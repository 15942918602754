import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Booth, EventInterface, PageData, PageTemplate, User } from '../interfaces/interfaces';
import { setPageData } from '../reduxToolkit/slices/commonSlice';
import { useSelector } from '../reduxToolkit/typedSelector';
import { createOrUpdatePage } from '../services/services';

export const usePages = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const user = useSelector((state) => state.common.user);
  const event = useSelector((state) => state.common.event ?? state.admin.event);

  const PAGENAME_REGEX = /(.*?)/;
  const PAGESLUG_REGEX = /^[A-Za-z0-9_-]*$/;
  /**
   * Checks if page is not only restricted to exhibitors
   * @param page
   * @returns
   */
  const checkNotRestrictedToOnlyExhibitors = (page: PageData, userParam?: User | null, eventParam?: EventInterface, boothsParam?: Booth[]) => {
    if (!page.isRestrictedOnlyToExhibitors) return true;

    // this function is also being called in dataLoader hook where redux states are not yet initiated
    // for that case we use params instead / all other cases will use redux states
    const currentUser = user ?? userParam;
    const currentEvent = event ?? eventParam;
    const currentBooths = currentEvent?.booths || boothsParam;

    if (
      !currentUser ||
      currentUser.role === 'PARTICIPANT_ANONYM' ||
      currentUser.role === 'ORGANIZATION_EXTERNAL_SPEAKER' ||
      !(
        currentUser?.organization?._id === currentEvent?.organization._id || !!currentBooths?.find((b) => b.organization._id === currentUser?.organization?._id)
      )
    )
      return false;

    return true;
  };

  /**
   * Filter event pages and remove special pages
   * @param pages
   * @returns
   */
  function filterEventPages(pages: PageData[]): PageData[] {
    return pages.filter(
      (page) =>
        page.showOnLivePage === true &&
        page.isHomePage === false &&
        page.isStatic === false &&
        page.isLandingPage === false &&
        page.isSignupPage === false &&
        page.isSignupSuccessPage === false &&
        page.visibility?.visibleWeb === true &&
        checkNotRestrictedToOnlyExhibitors(page)
    );
  }

  /**
   * Filter event pages and remove special pages
   * @param pages
   * @returns
   */
  function filterAccountLivePages(pages: PageData[]): PageData[] {
    return pages.filter(
      (page) =>
        page.isStatic === false &&
        page.isLandingPage === false &&
        page.isSignupPage === false &&
        page.isSignupSuccessPage === false &&
        page.visibility?.visibleWeb === true
    );
  }

  /**
   * Filter web pages
   * @param pages
   * @returns
   */
  function filterWebPages(pages: PageData[]): PageData[] {
    return pages.filter((page) => page.visibility?.visibleWeb === true && checkNotRestrictedToOnlyExhibitors(page));
  }

  /**
   * Filter event pages and remove live pages
   * @param pages
   * @returns
   */
  function filterNonLivePages(pages: PageData[]): PageData[] {
    return pages.filter(
      (page) =>
        page.isHomePage === false &&
        (page.isStatic === true || page.isLandingPage === true || page.isSignupPage === true || page.isSignupSuccessPage === true) &&
        page.visibility?.visibleWeb === true &&
        checkNotRestrictedToOnlyExhibitors(page)
    );
  }

  /**
   * Helper function to find a specific page in pagedata
   * @param pageName
   * @param pages
   * @returns
   */
  function findPageBySlugOrName(pageName: string, pages: PageData[]): PageData | undefined {
    return pages.find(
      (entry) => (entry.slug ? pageName === entry.slug : entry.name.toLowerCase() === pageName.toLowerCase()) && entry.visibility?.visibleWeb === true
    );
  }

  function findSpecialPage(type: 'landingpage' | 'signup' | 'signupsuccess', pages: PageData[]): PageData | undefined {
    return pages.find((entry) => {
      if (type === 'landingpage') return entry.isLandingPage;
      if (type === 'signup') return entry.isSignupPage;
      if (type === 'signupsuccess') return entry.isSignupSuccessPage;
    });
  }

  /**
   * Returns pagedata based on the pageid
   * @param pageId
   * @param pages
   * @returns
   */
  function findPageById(pageId: string, pages: PageData[]): PageData | undefined {
    return pages.find((entry) => entry._id === pageId && entry.visibility?.visibleWeb === true);
  }

  /**
   * Returns boolean if the page is a special page
   * @param pageData
   * @returns
   */
  function isSpecialPage(pageData: PageData): boolean {
    return pageData.isLandingPage || pageData.isSignupPage || pageData.isSignupSuccessPage || pageData.isStatic || false;
  }
  /**
   * Redirect special page types
   * @param pageData
   * @param eventSlug
   */
  function redirectSpecialPages(pageData: PageData, eventSlug: string, shallow = false): void {
    const url = getUrlForSpecialPage(pageData, eventSlug);
    if (url) {
      shallow && dispatch(setPageData(pageData));
      router.push(url, undefined, { shallow });
    }
  }

  /**
   * Returns the url for special pages
   * @param pageData
   * @param eventSlug
   * @returns
   */
  function getUrlForSpecialPage(pageData: PageData, eventSlug: string): string {
    if (pageData.isLandingPage) {
      return `/event/${eventSlug}`;
    }
    if (pageData.isSignupPage) {
      return `/event/${eventSlug}/signup`;
    }
    if (pageData.isSignupSuccessPage) {
      return `/event/${eventSlug}/signup_success`;
    }
    if (pageData.isStatic) {
      return `/event/${eventSlug}/${pageData.name}`;
    }
    return '';
  }

  /**
   * Returns the url for a pagedata object
   * @param pageData
   * @param eventSlug
   * @returns
   */
  function getUrlForPageData(pageData: PageData, eventSlug: string, team?: string): string {
    if (pageData.team && team) return `/t/${team}/${pageData.name}`;

    if (!pageData || !eventSlug) return '';
    if (isSpecialPage(pageData)) return getUrlForSpecialPage(pageData, eventSlug);
    const isLivePage = !pageData.isStatic;

    return `/event/${eventSlug}/${isLivePage ? `live/` : ``}${pageData.slug && pageData.slug !== '' ? pageData.slug : pageData.name}`;
  }

  /**
   * Returns the event root url
   */
  function getEventRootUrl(): string {
    if (!event) return `/`;
    return `/event/${event.slug}/`;
  }

  /**
   * Returns the url for a pagename if the page exists
   * @param pageName
   * @param pages
   * @param eventSlug
   * @returns
   */
  function getUrlForPageName(pageName: string, pages: PageData[], eventSlug: string): string {
    const pageData = findPageBySlugOrName(pageName, pages);
    if (!pageData) return '';
    return getUrlForPageData(pageData, eventSlug);
  }

  /**
   * Returns the url for a pageid if the page exists
   * @param pageId
   * @param pages
   * @param eventSlug
   * @returns
   */
  function getUrlForPageId(pageId?: string, pages?: PageData[], eventSlug?: string): string {
    if (!pageId || !pages || !eventSlug) return '';
    const pageData = findPageById(pageId, pages);
    if (!pageData) return '';
    return getUrlForPageData(pageData, eventSlug);
  }

  /**
   * Switches pages using the existing pagedata
   * @param pageData
   * @param eventSlug
   * @returns
   */
  function switchPageWithPageData(pageData: PageData, eventSlug: string, scroll = false, specialShallow = false, keepQueryParams = false): void {
    if (isSpecialPage(pageData)) return redirectSpecialPages(pageData, eventSlug, specialShallow);
    dispatch(setPageData(pageData));

    router.push(
      `/event/${eventSlug}/live/${pageData.slug && pageData.slug !== '' ? pageData.slug : pageData.name}${keepQueryParams ? `?${window.location.search}` : ''}`,
      undefined,
      {
        shallow: true,
        locale: i18n.language,
      }
    );
    if (window && scroll) {
      window.scroll({
        top: 0,
        left: 0,
      });
    }
  }

  /**
   * filter supported templates for the app pages modal in the dashboard
   * @param templates
   */
  const filterSupportedTemplates = (templates: PageTemplate[]) => {
    return templates.filter(
      (template) => !template.modules || template.modules.length === 0 || !template.modules.find((module: string) => event?.modules[module].active === false)
    );
  };

  /**
   * @name renderSettingItem
   * @description Returns the page setting element
   * @param SettingInput
   * @param title
   * @param description
   * @param error
   */
  const renderSettingItem = (SettingInput: JSX.Element, title?: string, description?: string, error?: string) => {
    return (
      <div className="w-full flex flex-row justify-between items-center px-4 py-2 border-b">
        <div className="flex flex-col space-y-2 justify-center">
          <p className="text-slate-900 font-bold">{title}</p>
          <p className="text-slate-600 text-sm">{description}</p>
          <p>{error}</p>
        </div>
        <div className="w-5/12 flex flex-row justify-end items-center">{SettingInput}</div>
      </div>
    );
  };

  async function createPage(name: string, pageType: { [key: string]: boolean }) {
    try {
      await createOrUpdatePage(undefined, {
        eventid: event?._id,
        name,
        showOnLivePage: false,
        template: {
          showHeaderArrow: false,
          headerArrowText: '',
          showSearch: false,
          showFilter: false,
          headerSentence: '',
          backgroundImage: undefined,
          excludeFilters: [],
          advancedFilters: [],
        },
        visibility: {
          visibleApp: false,
          visibleWeb: true,
        },
        ...pageType,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return {
    redirectSpecialPages,
    filterEventPages,
    filterAccountLivePages,
    filterNonLivePages,
    filterWebPages,
    filterSupportedTemplates,
    findPageBySlugOrName,
    findPageById,
    isSpecialPage,
    switchPageWithPageData,
    getUrlForPageName,
    getUrlForPageData,
    getUrlForPageId,
    findSpecialPage,
    checkNotRestrictedToOnlyExhibitors,
    renderSettingItem,
    getEventRootUrl,
    PAGENAME_REGEX,
    PAGESLUG_REGEX,
    createPage,
  };
};
