import { useTranslation } from 'next-i18next';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useLanguage } from '../../../hooks/useLanguage';
import { useSelector } from '../../../reduxToolkit/typedSelector';
import { DropdownProps, VyDropdown } from '../VyDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

interface LanguageSelectProps {
  maxHeight?: number;
  isEventLanguageSelect?: boolean;
  onLanguageSelect?: () => void;
  showTitle?: boolean;
  position?: DropdownProps['position'];
}

interface Language {
  value: string;
  label: string;
  code: string;
}

export const LanguageSelectDropdown: React.FC<LanguageSelectProps> = (props) => {
  const { onLanguageSelect, isEventLanguageSelect, showTitle, position = 'top-right' } = props;

  const event = useSelector((state) => state.common.event);

  const { i18n, t } = useTranslation();
  const { changeLanguage } = useLanguage();

  const handleChange = async (value: any) => {
    const selectedLocale = value;
    await changeLanguage(selectedLocale);
    onLanguageSelect?.();
  };

  const languageDisplay = (language: Language) => {
    return (
      <div className="flex items-center space-x-2">
        <ReactCountryFlag className="emojiFlag" countryCode={language.code} aria-label={language.label} />
        <span> {language.label}</span>
      </div>
    );
  };

  const languages = [
    { value: 'en', code: 'GB', label: t('common:languages.english') },
    { value: 'de', code: 'DE', label: t('common:languages.german') },
    { value: 'fr', code: 'FR', label: t('common:languages.french') },
  ];

  const filteredLanguages = languages.filter((language) => {
    if (!isEventLanguageSelect || !event || i18n.language === language.value) return true;
    return event.languages.includes(language.value);
  });

  return (
    <div className="flex items-center">
      <VyDropdown
        displayName={
          <>
            <FontAwesomeIcon icon={faGlobe} />
            {showTitle && <p className="inline ml-2 text-base">{t('common:language')}</p>}
          </>
        }
        disablePadding
        showArrow={false}
        position={position}
        children={
          <>
            {filteredLanguages.map((language) => (
              <div
                key={`language-${language.value}`}
                onClick={() => handleChange(language.value)}
                className={`px-4 py-2 ${
                  language.value === i18n.language ? 'bg-gray-100 text-slate-700' : 'text-white hover:text-slate-700 hover:bg-gray-100'
                } cursor-pointer rounded-md`}>
                {languageDisplay(language)}
              </div>
            ))}
          </>
        }
      />
    </div>
  );
};
