import { getQueryParam } from '../utils/functions';

export function useMobileApp() {
  function isAppView() {
    if (typeof window !== 'undefined') {
      if (getQueryParam('useappview') === 'true') {
        localStorage.setItem('useappview', 'true');
        return true;
      } else if (localStorage.getItem('useappview') === 'true') {
        return true;
      }
    }
    return false;
  }

  return { isAppView };
}
