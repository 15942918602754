import { isAfter, isBefore, isEqual, subMinutes } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Booth, EventInterface, MeetingDisplayStatus, Meetingroom, MeetingStatusUpdate, Slot } from '../interfaces/interfaces';
import { setModal, setPostRegistrationAction } from '../reduxToolkit/slices/commonSlice';
import { setSignupModalVisible } from '../reduxToolkit/slices/vbwSlice';
import { setMeeting } from '../reduxToolkit/slices/videoSlice';
import { useSelector } from '../reduxToolkit/typedSelector';
import { getMeeting } from '../services/services';
import { getRedirectParam } from '../utils/functions';
import { useEventAccess } from './useEventAccess';
import { useQueue } from './useQueue';

export function useMeeting() {
  const event = useSelector((state) => state.common.event || state.admin.event);
  const user = useSelector((state) => state.common.user);
  const meetingroomStatuses = useSelector((state) => state.common.meetingroomStatuses);
  const canjoinStatuses = useSelector((state) => state.common.canjoinStatuses);

  const appointment = useSelector((state) => state.common.approachingAppointment);
  const dispatch = useDispatch();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { loadUserData } = useEventAccess();

  const { joinQueue } = useQueue();

  function isOwnMeeting(meeting: Meetingroom, booth: Booth): boolean {
    if (user && user.organization && user.organization._id.toString() === booth.organization._id.toString()) {
      if (meeting.settings.allowJoinViaFrontend) return false;
      return true;
    }
    return false;
  }

  function _redirectToSignup(meeting?: Meetingroom) {
    if (
      event?.template?.uiTemplate === 'vystem-zukunftsrat' ||
      event?.template?.uiTemplate === 'vbw-additive-manufacturing' ||
      meeting?.meetingtype === 'presentation' ||
      meeting?.meetingtype === 'meeting' ||
      meeting?.meetingtype === 'networking'
    ) {
      dispatch(setSignupModalVisible(true));
      dispatch(
        setPostRegistrationAction(() => {
          loadUserData();
        })
      );
      return;
    }

    const as = `${event?.eventauth.registerPageUrl}?redirect=${btoa(unescape(encodeURIComponent(`${window.location.pathname}${window.location.search}`)))}`;
    return router.push(as);
  }

  function _checkRedirectToWaitingRoom(): boolean {
    return event?.settings?.presentations?.waitingRoomActive === true;
  }

  function _redirectToWaitingRoom(meetingroom: Meetingroom) {
    router.push(`/videochat/waitingroom/${meetingroom._id}?redirect=${btoa(unescape(encodeURIComponent(window.location.pathname)))}`);
  }

  async function join(meetingroom: Meetingroom, booth: Booth, status: MeetingDisplayStatus, allowQueue = true, forceJoin = false) {
    if (!event) return;

    if (!user) return _redirectToSignup(meetingroom);

    if (forceJoin) return redirectToRoom(meetingroom);

    if (meetingroom.meetingtype === 'presentation') {
      if (_checkRedirectToWaitingRoom()) return _redirectToWaitingRoom(meetingroom);
      redirectToRoom(meetingroom);
    }

    if (isOwnMeeting(meetingroom, booth)) {
      toast.success(t('event:meeting.own-room-error'));
      return;
    }

    if (
      appointment &&
      meetingroom?._id === appointment?.meetingroom?._id &&
      (isAfter(new Date(), new Date(appointment?.datetime_start)) || isEqual(new Date(), new Date(appointment?.datetime_start))) &&
      isBefore(new Date(), new Date(appointment?.datetime_end))
    ) {
      return redirectToRoom(meetingroom, undefined, undefined, undefined, undefined, undefined, undefined, appointment._id);
    }

    switch (status) {
      case 'open':
        redirectToRoom(meetingroom);
        break;
      case 'queue':
        if ((meetingroom.meetingtype !== 'conference', allowQueue)) {
          const status = await joinQueue(meetingroom);
          if (status === true) redirectToRoom(meetingroom);
        } else toast.success(t('event:meeting.room-full'));
        break;
      case 'offline':
        toast.success(t('event:meeting.room-offline'));
        break;
      default:
        toast.success(t('event:meeting.room-error'));
    }
  }

  function _buildHref(
    meetingId: string,
    params: { joinToken?: string; withoutRedirectParam?: boolean; pickupFullParams?: boolean; showDevicePreview?: boolean; appointmentId?: string }
  ) {
    const languageParam = ['en', 'fr'].includes(i18n.language) ? `${i18n.language}/` : '';

    let href = `${window.origin}/${languageParam}videochat/${meetingId}`;

    if (params.pickupFullParams) {
      href += `${window.location.search}`;
    } else {
      href += '?';
      if (params.joinToken) {
        href += `jointoken=${params.joinToken}&`;
      }
      if (!params.withoutRedirectParam) {
        href += `${getRedirectParam()}&`;
      }
      if (params.showDevicePreview === false) {
        href += 'showpreview=false&';
      }
      if (params.appointmentId) {
        href += `appointment=${params.appointmentId}`;
      }
    }

    return href;
  }

  function _redirectV2(meetingId: string, joinToken?: string, withoutRedirectParam?: boolean): void {
    const languageParam = ['en', 'fr'].includes(i18n.language) ? `${i18n.language}/` : '';
    window.location.href = `${process.env.baseURL}/video/${languageParam}${meetingId}?${withoutRedirectParam !== true ? getRedirectParam() : ''}${
      joinToken ? `&jointoken=${joinToken}` : ''
    }`;
  }

  function _redirectV3(
    meetingId: string,
    joinToken?: string,
    withoutRedirectParam?: boolean,
    pickupFullParams?: boolean,
    showDevicePreview?: boolean,
    appointmentId?: string
  ) {
    window.location.href = _buildHref(meetingId, { joinToken, withoutRedirectParam, pickupFullParams, showDevicePreview, appointmentId });
  }

  function redirectToRoom(
    meeting?: Meetingroom,
    meetingId?: string,
    joinToken?: string,
    eventObject?: EventInterface,
    withoutRedirectParam?: boolean,
    pickupFullParams?: boolean, // Useful when user is coming from a e.g. waiting room and just forwards all query params
    showDevicePreview?: boolean,
    appointmentId?: string
  ) {
    if ((!event?.videomodes && !eventObject?.videomodes) || (!meeting && !meetingId)) return;

    if (!user && event) {
      if (event.modules.guestmanagement.settings.authUserAfterSignupWhenPossible === false) {
        _redirectToSignup(meeting);
      } else {
        dispatch(
          setPostRegistrationAction(() => {
            loadUserData();
          })
        );
      }
      return;
    }

    const meetingroomId = meeting?._id || meetingId;
    const videoModes = event?.videomodes || eventObject?.videomodes;

    let version: string | null = videoModes!['conference'].version;
    if (meeting) {
      switch (meeting.meetingtype) {
        case 'meeting':
          version = videoModes!['meeting'].version;
          break;
        case 'presentation':
          version = videoModes!['presentation'].version;
          break;
        case 'networking':
          version = videoModes!['networking'].version;
          break;
        case 'conference':
          version = videoModes!['conference'].version;
          break;
        case 'breakout':
          version = videoModes!['breakout'].version;
          break;
        default:
          version = videoModes!['conference'].version;
          break;
      }
    }

    if (version === 'v3') return _redirectV3(meetingroomId!, joinToken, withoutRedirectParam, pickupFullParams, showDevicePreview, appointmentId);
    return _redirectV2(meetingroomId!, joinToken, withoutRedirectParam);
  }

  async function fetchAndSetMeeting(meetingId: string) {
    const meeting = await getMeeting(meetingId);
    dispatch(setMeeting(meeting));
  }

  /**
   * Function that decompresses the meetingstatus from a MeetingStatuUpdate Object
   * @param payload
   * @returns
   */
  function _determinDisplayStatus(payload: MeetingStatusUpdate): MeetingDisplayStatus {
    if (payload.s.L === 1) return 'live';
    if (payload.s.C === 1) return 'open';
    if (payload.s.A === 1 && payload.s.C === 0) return 'queue';

    return 'offline';
  }

  /**
   * Convert an incoming meetingstatus update object
   * to internal meeting status object
   * @param meetingPayload
   * @returns
   */
  function convertMeetingUpdate(meetingPayload: MeetingStatusUpdate): { meetingId: string; canJoin: boolean; displayStatus: MeetingDisplayStatus } {
    return {
      meetingId: meetingPayload.id,
      canJoin: meetingPayload.s.C === 1,
      displayStatus: _determinDisplayStatus(meetingPayload),
    };
  }

  /**
   * Joins a meeting or open modal with message if not joinable
   * @param slot
   */
  async function goToMeeting(slot: Slot) {
    const displaystatus = meetingroomStatuses[slot.meetingroom._id];
    const canjoinstatus = canjoinStatuses[slot.meetingroom._id];
    const joinStatus = canjoinstatus != undefined ? canjoinstatus : slot.meetingroom.status?.canjoin;
    if (slot.status === 'old') {
      dispatch(
        setModal({
          open: true,
          answer: null,
          sound: false,
          question: t('event:presentation-over'),
          response: false,
          backgroundImage: slot.meetingroom.presentationBackground || slot.meetingroom.booth.logo || slot.meetingroom.booth.backgroundImage,
        })
      );
    } else if (
      !event?.settings?.presentations?.waitingRoomActive &&
      isBefore(new Date(), subMinutes(new Date(slot.datetime_start), event?.settings.presentations.blockTime || 15)) &&
      !event?.settings.presentationCountdownEnabled
    ) {
      dispatch(
        setModal({
          open: true,
          answer: null,
          sound: false,
          question: t('slots:block-time-message'),
          response: false,
          backgroundImage: slot.meetingroom.presentationBackground || slot.meetingroom.booth.logo || slot.meetingroom.booth.backgroundImage,
        })
      );
    } else if (joinStatus === false) {
      dispatch(
        setModal({
          open: true,
          answer: null,
          sound: false,
          question: event?.template?.individualNames?.meetingFullNoticeText || t('event:presentation-user-limit'),
          response: false,
          backgroundImage: slot.meetingroom.presentationBackground || slot.meetingroom.booth.logo || slot.meetingroom.booth.backgroundImage,
        })
      );
    } else join(slot.meetingroom, slot.meetingroom.booth, displaystatus);
  }
  return {
    join,
    isOwnMeeting,
    redirectToRoom,
    fetchAndSetMeeting,
    convertMeetingUpdate,
    goToMeeting,
  };
}
