import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Popup from 'reactjs-popup';
import { useMobileApp } from '../hooks/useMobileApp';
import { usePages } from '../hooks/usePages';
import { usePrivilege } from '../hooks/usePrivilege';
import { usePusher } from '../hooks/usePusher';
import SignIn from '../pages/login';
import { setAdminEvent } from '../reduxToolkit/slices/adminSlice';
import {
  fetchAndSetAllMatches,
  fetchAndSetOwnEventPrivilegeRequests,
  setAxiosError,
  setEventRegistration,
  setEventRegistrationId,
  setSpeeddatingState,
} from '../reduxToolkit/slices/commonSlice';
import { useSelector } from '../reduxToolkit/typedSelector';
import { setTokenFromLocalStorage, setTokenFromQueryParameters } from '../utils/auth';
import setAxiosInterceptors from '../utils/axiosInterceptors';
import { SPEEDDATINGSTATE } from '../utils/enums';
import { SupportHeader } from './common/SupportHeader';
import VyModal from './common/VyModal';
import { AppointmentCard } from './event/templates/VystemLight/appointment/AppointmentCard';
import { useLanguage } from '../hooks/useLanguage';
import { VYSTEMBLUE } from '../static/constants';

axios.defaults.baseURL = `${process?.env?.baseAPIURL}${process.env.basePath}`;

const Global = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { switchPageWithPageData } = usePages();

  const axiosError = useSelector(({ common }) => common.axiosError);

  const event = useSelector((state) => state.common.event || state.admin.event);
  const eventRegistration = useSelector((state) => state.common.eventRegistration);
  const eventRegistrationId = useSelector((state) => state.common.eventRegistrationId);

  const booth = useSelector((state) => state.admin.userbooth);
  const pusher = useSelector((state) => state.common.pusher);
  const user = useSelector((state) => state.common.user);
  const isLoading = useSelector((state) => state.common.isLoadingPageData);
  const matchingState = useSelector((state) => state.common.speeddating.state);

  const { initPusher, handleEventChange, setupUserEventChannel, setupPresenceChannel, setupBoothChannel } = usePusher();
  const { initUserPrivileges, initUserBoothPrivilege } = usePrivilege();
  const { isAppView } = useMobileApp();

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  useEffect(() => {
    handleEventChange(event?._id);
    setupBoothChannel(event?._id);
  }, [event, pusher]);

  useEffect(() => {
    setupUserEventChannel(event?._id, user?._id);
  }, [event, pusher, user]);

  const page = useSelector((state) => state.common.pagedata);

  useEffect(() => {
    if (!isLoading) setupPresenceChannel(event?._id, page?._id, user?._id, event?.settings?.allowPresenceTracking);
  }, [event, pusher, user, page, isLoading, eventRegistration]);

  useEffect(() => {
    setTokenFromLocalStorage();

    setAxiosInterceptors();
    initPusher();

    checkCookieExp();
    const interval = setInterval(() => {
      checkCookieExp();
    }, 10000);

    if (isAppView()) {
      setTokenFromQueryParameters();
    }

    if (typeof window !== 'undefined') {
      // Listener to enable shallow routing in mobile app webview
      window.addEventListener('message', (message) => {
        try {
          if (typeof message.data !== 'string') {
            return;
          }
        } catch (err) {
          console.error(err);
        }

        try {
          const data = JSON.parse(message.data);
          if (data.page && data.eventSlug) {
            switchPageWithPageData(data.page, data.eventSlug, false, undefined, true);
          }
        } catch (err) {
          console.error(err);
        }
      });
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  // this because we cannot access addToast outside of the component
  useEffect(() => {
    if (axiosError) {
      if (axiosError !== 'no session found' && axiosError !== 'Dir fehlen leider die nötigen Berechtigungen, um die Anfrage auszuführen!') {
        toast.error(axiosError);
        dispatch(setAxiosError(null));
      }
    }
  }, [axiosError]);

  /**
   * Registration utils
   *
   * We always set the appropriate registration when the user
   * or the event changes
   *
   * The eventregistration is typically within the user object
   */
  const dispatch = useDispatch();

  const registration = useSelector((state) => state.common.eventRegistration);
  const approachingAppointment = useSelector((state) => state.common.approachingAppointment);

  useEffect(() => {
    const findRegistrationForEvent = () => {
      if (!user || !event) return null;

      return user.registrations?.find(
        (registration) =>
          registration.event === event._id && !registration.datetime_deleted && (!eventRegistrationId || registration._id === eventRegistrationId)
      );
    };

    if ((event && user && !registration) || eventRegistrationId !== registration?._id) {
      const registration = findRegistrationForEvent();
      dispatch(setEventRegistration(registration));
      if (eventRegistrationId) dispatch(setEventRegistrationId(registration?._id));
    }

    if (registration && !user) {
      dispatch(setEventRegistration(null));
    }

    if (registration) {
      dispatch(fetchAndSetAllMatches(registration._id));
    }

    /**
     * Event and user do not match hence we remove the registration
     * and try to set the correct one
     */
    if (event && user && registration?.event !== event._id) {
      dispatch(setEventRegistration(null));
      dispatch(setEventRegistration(findRegistrationForEvent()));
    }

    if (event && user) {
      if (user?.role !== 'PARTICIPANT_ANONYM') initUserPrivileges(event._id);
    }

    /**
     * Account related initializations
     */
    if (event && user) {
      if (user.role === 'ORGANIZATION_SUBUSER') dispatch(fetchAndSetOwnEventPrivilegeRequests(event._id));
    }
  }, [user, event, eventRegistrationId]);

  useEffect(() => {
    if (!user || !booth) return;
    if (user?.role !== 'PARTICIPANT_ANONYM') initUserBoothPrivilege(booth._id);
  }, [user, booth]);

  useEffect(() => {
    /* const checkCurrentRegistrationForRestrictedFields = (restrictedFields: RestrictedFieldValuePair[], eventOrganization: string) => {

      if (user && user?.role !== 'PARTICIPANT_ANONYM') return true;
      if (!registration) return false;

      const match = registration.registrationFields?.find((f) => restrictedFields?.findIndex((o) => o.value === f.value && o.field === f.field._id) !== -1);
      if (match) return true;
      return false;
    };

   
      TODFIX: https://isardigital.atlassian.net/browse/VY-3598
      if (event) {
      dispatch(
        setPages(
          pages?.filter(
            (page) =>
              !page.isRestrictOnRegistrationFields ||
              page.restrictedByRegistrationFields?.length === 0 ||
              checkCurrentRegistrationForRestrictedFields(page.restrictedByRegistrationFields, event.organization?._id)
          )
        )
      );
    } */
  }, [registration, event, user]);

  useEffect(() => {
    // when on non admin page, admin event is set to null
    if (!router.asPath?.includes('/account/event') && !router.asPath?.includes('/account/booth')) dispatch(setAdminEvent(null));

    // end matching if active as the component is currently page bound
    if (matchingState !== SPEEDDATINGSTATE.INACTIVE) {
      toast.success(t('matching:canceled-matching-because-switch'));
      dispatch(setSpeeddatingState(SPEEDDATINGSTATE.INACTIVE));
    }
  }, [router]);

  const checkCookieExp = () => {
    try {
      // Show Login Modal if jwt token expires
      if (typeof window !== 'undefined' && router.asPath?.includes('/account/')) {
        const jwt = localStorage.getItem('access_token');
        if (jwt) {
          const token: any = jwt_decode(jwt);

          if (new Date().getTime() / 1000 > token.exp) {
            setShowLoginModal(true);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // global css classes
  return (
    <>
      <VyModal onClose={() => setShowLoginModal(false)} open={showLoginModal}>
        {() => <SignIn />}
      </VyModal>

      <SupportHeader target="event" />

      {approachingAppointment && event?.template?.uiTemplate !== 'vbw-additive-manufacturing' && !router.pathname?.includes('videochat') && (
        <Popup
          lockScroll
          modal
          open={true}
          onClose={() => {
            if (router?.query?.appointment) {
              router.replace(router.asPath?.split('?')[0], undefined, { shallow: true });
            }
          }}
          contentStyle={{
            border: 'none',
            padding: 0,
          }}>
          <AppointmentCard appointmentId={approachingAppointment._id} />
        </Popup>
      )}
      <style global jsx>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        :root {
          --vystem-blue: ${VYSTEMBLUE};
          --vystem-hover: #036aa1;
          --vystem-warn: #ffe24f;
          --vystem-danger: #f54254;
          --vystem-success: #40db67;
          --vystem-blue-dark: #216ecc;
          --vystem-warn-dark: #e3c946;
          --vystem-danger-dark: #b50b35;
          --vystem-success-dark: #2e9949;
          --vystem-blue-text: white;
          --vystem-warn-text: black;
          --vystem-danger-text: white;
          --vystem-success-text: white;
          --vystem-button-text: white;
          --isdi-grey: #333333;
        }

        // start custom font declarations here

        @font-face {
          font-family: Barlow-Regular;
          src: url('/fonts/custom/Barlow-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: MetaPlusLF-Bold;
          src: url('/fonts/custom/MetaPlusLF-Bold.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: MetaPlusLF-Regular;
          src: url('/fonts/custom/MetaPlusLF-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: BebasNeue-Regular;
          src: url('/fonts/custom/BebasNeue-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidMOD-Regular;
          src: url('/fonts/custom/CorpidMOD-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidC1Cd-Regular;
          src: url('/fonts/custom/CorpidC1Cd-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: OpenSans-Regular;
          src: url('/fonts/custom/OpenSans-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: AmazonEmber-Light;
          src: url('/fonts/custom/AmazonEmber-Light.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Andale-Mono;
          src: url('/fonts/custom/Andale-Mono.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Avenir-Medium-09;
          src: url('/fonts/custom/Avenir-Medium-09.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Graphik-Super;
          src: url('/fonts/custom/Graphik-Super.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: MarkPro-Light;
          src: url('/fonts/custom/MarkPro-Light.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Roboto-Regular;
          src: url('/fonts/custom/Roboto-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: DINPro-Regular;
          src: url('/fonts/custom/DINPro-Regular.otf') format('opentype');
        }

        @font-face {
          font-family: SourceSansPro-Regular;
          src: url('/fonts/custom/SourceSansPro-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: BundesSans-Web-Regular;
          src: url('/fonts/custom/BundesSans-Web-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: AmazonEmber-Regular;
          src: url('/fonts/custom/AmazonEmber-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: TT-Interphases-Regular;
          src: url('/fonts/custom/TT-Interphases-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Europa;
          src: url('/fonts/Europa/Europa-Bold.woff2') format('woff2'), url('/fonts/Europa/Europa-Bold.woff') format('woff'),
            url('/fonts/Europa/Europa-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Europa;
          src: url('/fonts/Europa/Europa-Regular.woff2') format('woff2'), url('/fonts/Europa/Europa-Regular.woff') format('woff'),
            url('/fonts/Europa/Europa-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: NeueHaasUnica;
          src: url('/fonts/Neue-Haas-Unica/Neue-Haas-Unica-W01-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Gilroy;
          src: url('/fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2'), url('/fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
            url('/video/fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Gilroy;
          src: url('/fonts/Gilroy/Gilroy-Light.woff2') format('woff2'), url('/fonts/Gilroy/Gilroy-Light.woff') format('woff'),
            url('/fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: GeogrotesqueMedium;
          src: url('/fonts/custom/Geogrotesque-medium.otf') format('opentype');
        }

        @font-face {
          font-family: LidlFontPro;
          src: url('/fonts/custom/LidlFontPro-Regular.ttf') format('truetype');
        }

        @font-face {
          font-family: LidlFontProBold;
          src: url('/fonts/custom/LidlFontPro-Bold.ttf') format('truetype');
        }
        @font-face {
          font-family: UnboundedLight;
          src: url('/fonts/Unbounded/Unbounded-Light.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: UnboundedSemiBold;
          src: url('/fonts/Unbounded/Unbounded-SemiBold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: MarkPro-Heavy;
          src: url('/fonts/custom/MarkPro-Heavy.otf') format('truetype');
        }

        @font-face {
          font-family: Corpid300;
          src: url('/fonts/Corpid/corpid_c1cd_300_eot.eot') format('eot'), url('/fonts/Corpid/corpid_c1cd_300_woff.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Corpid500;
          src: url('/fonts/Corpid/corpid_c1cd_500_eot.eot') format('eot'), url('/fonts/Corpid/corpid_c1cd_500_woff.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidLight;
          src: url('/fonts/Corpid/corpidc1_300_light_eot.eot') format('eot'), url('/fonts/Corpid/corpidc1_300_light_woff.woff') format('woff'),
            url('/fonts/Corpid/corpidc1_300_light_woff2.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidLightItalic;
          src: url('/fonts/Corpid/corpidc1_300_lightitalic_eot.eot') format('eot'), url('/fonts/Corpid/corpidc1_300_lightitalic_woff.woff') format('woff'),
            url('/fonts/Corpid/corpidc1_300_lightitalic_woff2.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidRegular;
          src: url('/fonts/Corpid/corpidc1_500_regular_eot.eot') format('eot'), url('/fonts/Corpid/corpidc1_500_regular_woff.woff') format('woff'),
            url('/fonts/Corpid/corpidc1_500_regular_woff2.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidRegularItalic;
          src: url('/fonts/Corpid/corpidc1_500i_regularitalic_eot.eot') format('eot'), url('/fonts/Corpid/corpidc1_500i_regularitalic_woff.woff') format('woff'),
            url('/fonts/Corpid/corpidc1_500i_regularitalic_woff2.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidBold;
          src: url('/fonts/Corpid/corpidc1_700_bold_eot.eot') format('eot'), url('/fonts/Corpid/corpidc1_700_bold_woff.woff') format('woff'),
            url('/fonts/Corpid/corpidc1_700_bold_woff2.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: CorpidBoldItalic;
          src: url('/fonts/Corpid/corpidc1_700i_bolditalic_eot.eot') format('eot'), url('/fonts/Corpid/corpidc1_700i_bolditalic_woff.woff') format('woff'),
            url('/fonts/Corpid/corpidc1_700i_bolditalic_woff2.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: RobotoBold;
          src: url('/fonts/custom/Roboto-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: RobotoMedium;
          src: url('/fonts/custom/Roboto-Medium.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: RobotoRegular;
          src: url('/fonts/custom/Roboto-Regular.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: RobotoLight;
          src: url('/fonts/custom/Roboto-Light.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: RobotoThin;
          src: url('/fonts/custom/Roboto-Thin.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }
        @font-face {
          font-family: PerfectStrangers;
          src: url('/fonts/custom/PerfectStrangers.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Montserrat-VariableFont;
          src: url('/fonts/custom/Montserrat-VariableFont_wght.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Inter-VariableFont;
          src: url('/fonts/custom/ Inter-VariableFont_slnt_wght.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: NettoOT;
          src: url('/fonts/custom/NettoOT.otf') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: NettoOT-Bold;
          src: url('/fonts/custom/NettoOT-Bold.otf') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: NettoOT[17];
          src: url('/fonts/custom/NettoOT[17].otf') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Poppins-SemiBold;
          src: url('/fonts/custom/Poppins-SemiBold.ttf') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Poppins-Regular;
          src: url('/fonts/custom/Poppins-Regular.ttf') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        @font-face {
          font-family: Poppins-Bold;
          src: url('/fonts/custom/Poppins-Bold.ttf') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
        }

        .search__icon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 16px;
          color: grey;
        }

        .arrow_down {
          margin-top: 1.4rem;
          width: 100px !important;
          height: 55px;
        }

        .slick-prev {
          left: 20px;
          height: 50px;
          z-index: 1;
        }

        .slick-next {
          right: 20px;
          height: 50px;
        }

        .play-button-video {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 80px !important;
          z-index: 2;
          height: 80px;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.6);
          pointer-events: none;
        }

        .tooltip {
          background: rgba(51, 51, 51, 0.8) !important;
          border-radius: 8px;
        }

        .js3q-player {
          z-index: 0 !important;
        }

        .bg-vystem-brown {
          background-color: #2a2a2a;
        }

        .bg-vystem-gray {
          background-color: #e8e8e8;
        }

        .selected-item {
          color: white !important;
          background-color: var(--vystem-blue) !important;
        }

        .selected-icon {
          color: white !important;
        }

        .react-toast-notifications__container {
          z-index: 2000 !important;
        }
      `}</style>
    </>
  );
};

export default Global;
