import { useDispatch } from 'react-redux';
import { setVideoEffect, VideoEffectOptions } from '../../../reduxToolkit/slices/videoSlice';
import { useSelector } from '../../../reduxToolkit/typedSelector';

export function useBackgroundEffects() {
  const dispatch = useDispatch();
  const videoEffect = useSelector(({ video }) => video.videoEffect);

  async function loadBackgroundImage(imageUrl?: string | null) {
    const img = new Image();
    img.src = imageUrl ? imageUrl : '/vystem_wallpaper.jpg';
    await img.decode();
    return img;
  }

  async function toggleBackgroundEffects(active: boolean) {
    dispatch(
      setVideoEffect({
        ...videoEffect,
        useBackgroundEffect: active,
      })
    );
  }

  async function activateBgBlur(active: boolean) {
    dispatch(
      setVideoEffect({
        ...videoEffect,
        useBackgroundEffect: active,
        backgroundEffectMode: 'blur',
      })
    );
  }

  async function activateVirtualBg(active: boolean) {
    dispatch(
      setVideoEffect({
        ...videoEffect,
        useBackgroundEffect: active,
        backgroundEffectMode: 'image',
        backgroundImageData: active ? await loadBackgroundImage(videoEffect?.backgroundImageUrl) : null,
      })
    );
  }

  function deleteVirtualBg() {
    dispatch(
      setVideoEffect({
        ...videoEffect,
        backgroundImageUrl: null,
      })
    );
  }

  function setVirtualBackgroundUrl(localImageUrl: string) {
    dispatch(
      setVideoEffect({
        ...videoEffect,
        backgroundImageUrl: localImageUrl,
      })
    );
  }

  function setStudioDefaultValues() {
    dispatch(
      setVideoEffect({
        ...videoEffect,
        showUsernameBanner: true,
      })
    );
  }

  const isBgBlurEnabled = videoEffect?.useBackgroundEffect && videoEffect?.backgroundEffectMode === 'blur';
  const isVirtualBgEnabled = videoEffect?.useBackgroundEffect && videoEffect?.backgroundEffectMode === 'image';

  return {
    isVirtualBgEnabled,
    isBgBlurEnabled,
    loadBackgroundImage,
    activateBgBlur,
    activateVirtualBg,
    deleteVirtualBg,
    setVirtualBackgroundUrl,
    setStudioDefaultValues,
    toggleBackgroundEffects,
  };
}

export class VideoFrameProcessor {
  constructor() {}

  drawNameOnCanvas(canvas: HTMLCanvasElement, videoEffectOptions: VideoEffectOptions, name?: string, bgColor = '#584fa8', textColor = 'white') {
    if (!canvas) throw new Error('no Canvas');
    if (!videoEffectOptions.showUsernameBanner || !name) return canvas;

    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) return;
    canvasContext.beginPath();
    canvasContext.fillStyle = bgColor;
    const yBoxPos = canvasContext.canvas.height - (canvasContext.canvas.height / 100) * 20;
    const boxWidth = (canvasContext.canvas.width / 100) * 40;
    const boxHeight = (canvasContext.canvas.height / 100) * 10;

    canvasContext.rect(0, yBoxPos, boxWidth, boxHeight); // x, y of top-left, width, height
    canvasContext.fill();
    // Text
    canvasContext.font = '20px Monospace';
    canvasContext.fillStyle = textColor;
    canvasContext.fillText(name, 10, yBoxPos + 30); // x, y of top-left*/
    return canvas;
  }
}
