import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import AttendeeAvatar from '../components/event/templates/VystemLight/AttendeeAvatar';
import { AttendeeMessage, EventRegistrationField, MessagingAttendee, MessagingConversation, User } from '../interfaces/interfaces';
import {
  fetchAndSetAttendeeMessaging,
  setAttendeeMessaging,
  setDatetimeLastNewMessageNotification,
  setLastNewMessageNotificationKey,
  setMessagingOpenConversation,
} from '../reduxToolkit/slices/commonSlice';
import store from '../reduxToolkit/store';
import { useSelector } from '../reduxToolkit/typedSelector';
import { conversationKeyFromMessage } from '../services/helper';
import { usePermission } from './usePermission';

const useMessaging = () => {
  const dispatch = useDispatch();
  const { isBoothHost, isEventHost } = usePermission();

  const user = useSelector((state) => state.common.user);
  const messages = useSelector((state) => state.common.attendeeMessaging?.messages);
  const conversations = useSelector((state) => state.common.attendeeMessaging?.conversations);
  const messaging = useSelector((state) => state.common.attendeeMessaging);

  function _userToMessagingAttendee(user: User): MessagingAttendee {
    if (!user) throw new Error('user not defined');

    return {
      _id: user._id,
      avatar: user.avatar,
      name: user.name,
      datetimeLastRealtimeConnect: user.datetimeLastRealtimeConnect,
      datetimeLastRealtimeDisconnect: user.datetimeLastRealtimeDisconnect,
      currentMeeting: user.currentMeeting,
      registrationFields: [], // TODO
      profileStatus: user.profileStatus,
    };
  }

  function filterAndSortMessagesForConversation(userAId: string, userBId: string) {
    const filteredMessages =
      messages?.filter((message) => {
        if (message.sender === userAId && message.recepient === userBId) return true;
        if (message.sender === userBId && message.recepient === userAId) return true;
        return false;
      }) || [];

    return filteredMessages.sort((a, b) => new Date(b.datetime_created).getTime() - new Date(a.datetime_created).getTime());
  }

  function openMessagingConversationFromAttendee(attendee?: MessagingAttendee): void {
    if (!user || !attendee) return undefined;
    // TODO: Find already existing conversation
    // TODO: Move messages directly into conversation component

    const conversation = {
      userA: _userToMessagingAttendee(user),
      userB: attendee,
      messages: filterAndSortMessagesForConversation(user._id, attendee._id),
    };

    dispatch(setMessagingOpenConversation(conversation));
  }

  function rebuildConversations() {
    const allMessagesInConvos = conversations?.map((c) => c.messages).flat() || [];
    const attendeeMessages = messages ?? [];
    if (attendeeMessages.length === allMessagesInConvos.length) {
      return;
    }

    // TODO: Consider moving this to state
    const messagesWithKey: AttendeeMessage[] = messages?.map((m) => ({ ...m, conversationKey: m.conversationKey ?? conversationKeyFromMessage(m) })) || [];
    const conversationsWithFreshMessages =
      conversations?.map((convo) => ({ ...convo, messages: messagesWithKey.filter((message) => message.conversationKey === convo.conversationKey) })) || [];

    const sortedConversations = conversationsWithFreshMessages.sort(
      (a, b) => new Date(b.messages[0].datetime_created).getTime() - new Date(a.messages[0].datetime_created).getTime()
    );

    dispatch(setAttendeeMessaging({ ...messaging, conversations: sortedConversations }));
  }

  function checkIfConversationIsListed(key: string, conversationObject?: MessagingConversation[]) {
    const convos = conversations ?? conversationObject;
    console.log('Trying to find key ', key, 'conversations', convos);
    return !!convos?.find((conversation) => conversation.conversationKey === key);
  }

  const onNewConversation = (message: AttendeeMessage) => {
    const state = store.getState();

    console.log('On new conversation called');
    /**
     * When a new message comes in we make sure that
     * we have a conversation. If not we reload the
     * conversations data.
     */
    const key = conversationKeyFromMessage(message);
    if (!checkIfConversationIsListed(key, state.common.attendeeMessaging?.conversations)) {
      dispatch(fetchAndSetAttendeeMessaging(message.event));
    }
    const attendeeMessaging = state.common.attendeeMessaging;
    const openAttendeeMessaging = state.common.openAttendeeMessaging;
    const lastNewMessageNotificationKey = state.common.lastNewMessageNotificationKey;

    if (lastNewMessageNotificationKey === message._id) return;

    if (!openAttendeeMessaging && (state.common.eventRegistration?.messagingAllowNotifications || isEventHost() || isBoothHost())) {
      const sender = attendeeMessaging?.attendees?.find((a) => a._id === message.sender);

      const suffix = sender?.registrationFields?.find((field: { value: string; field: EventRegistrationField }) => field.field.isSuffix)?.value;

      const toastMessage = (
        <div className="flex flex-row items-center space-x-2 cursor-pointer" onClick={() => openMessagingConversationFromAttendee(sender)}>
          {sender && <AttendeeAvatar attendee={sender} clickable={false} />}
          <div className="flex flex-col">
            <div className="flex">
              <p className="text-slate-500">{suffix}</p>
              <p className="text-slate-800 font-bold"> {sender?.name ?? ''}</p>
            </div>
            <p className="message text-slate-700">{message.message}</p>
            <style jsx>
              {`
                .message {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  word-break: break-word;
                }
              `}
            </style>
          </div>
        </div>
      );
      toast(toastMessage);
      dispatch(setDatetimeLastNewMessageNotification(new Date()));
      dispatch(setLastNewMessageNotificationKey(message._id));
    }
  };

  return {
    openMessagingConversationFromAttendee,
    filterAndSortMessagesForConversation,
    onNewConversation,
    rebuildConversations,
  };
};
export default useMessaging;
