import cookie from 'cookie';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAllCookies } from '../../services/helper';
import { VyConfirm } from './VyConfirm';

const ResetData: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();

  function cleanAll() {
    localStorage.clear();
    deleteAllCookies();

    let retrycount = 0;
    while (retrycount < 10) {
      const parsedCookie = cookie.parse(document.cookie);
      if (parsedCookie['sde.token'] === undefined) break;
      else retrycount++;
    }
    router.push('/login');
  }

  return (
    <div className="cursor-pointer">
      <VyConfirm message={t('common:reset-data-message')} onConfirm={cleanAll}>
        {t('common:reset-data')}
      </VyConfirm>
    </div>
  );
};

export default ResetData;
