import { Booth, EventInterface, User } from '../interfaces/interfaces';
import { useSelector } from '../reduxToolkit/typedSelector';

export function usePermission() {
  const user = useSelector(({ common }) => common.user);
  const booths = useSelector(({ common }) => common.booths);
  const adminBooth = useSelector(({ admin }) => admin.userbooth);
  const event = useSelector((state) => state.admin.event || state.common.event);

  function isEventHost(userObject: User | null | undefined = user, eventData: EventInterface | null | undefined = event): boolean {
    if (user?.role === 'PARTICIPANT_ANONYM') return false;

    const eventOrg = eventData?.organization?._id ?? eventData?.organization.toString();
    if (!userObject || !userObject.organization || !eventOrg) return false;

    return userObject.organization._id.toString() === eventOrg.toString();
  }

  function isBoothHost(userObject: User | null | undefined = user, boothData: Booth[] | undefined = booths ?? []): boolean {
    if (adminBooth) return true;
    if (!userObject || !userObject.organization || !boothData || boothData.length === 0) return false;

    return boothData.filter((booth) => booth?.organization?._id?.toString() === userObject?.organization?._id?.toString()).length > 0;
  }

  function isEventOrg(userObject: User | null | undefined = user, eventObject: EventInterface | null | undefined = event) {
    const eventOrganizationId = eventObject?.organization?._id ?? eventObject?.organization;
    const userOrganizationId = userObject?.organization?._id ?? userObject?.organization;

    if (!userOrganizationId || !eventOrganizationId) return false;
    return eventOrganizationId === userOrganizationId;
  }

  /**
   * This determines whether a user is above a guest, such as an external speaker, who might not be assigned to a booth but has a higher role than PARTICIPANT_ANONYM.
   */
  function hasEventRelatedRole(
    userObject: User | null | undefined = user,
    eventData: EventInterface | null | undefined = event,
    boothData: Booth[] | undefined = booths ?? []
  ): boolean {
    return (
      (userObject &&
        userObject?.role &&
        userObject?.role !== 'PARTICIPANT_ANONYM' &&
        (isEventHost(userObject, eventData) || isBoothHost(userObject, boothData))) ??
      false
    );
  }

  return { isEventHost, isBoothHost, hasEventRelatedRole, isEventOrg };
}
