import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

export interface DropdownProps {
  displayName: string | ReactNode;
  id?: string;
  children?: React.ReactElement;
  onSearch?: (value: string) => void;
  showArrow?: boolean;
  disablePadding?: boolean;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  dropdownClassName?: string;
}

//ORIGIN: https://tailwindcomponents.com/component/dropdown-with-search
export const VyDropdown = ({
  displayName,
  id,
  children,
  onSearch,
  showArrow = true,
  disablePadding = false,
  dropdownClassName = '',
  position = 'bottom-left',
}: DropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const [contentWidth, setContentWidth] = useState<number>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  function toggleDropdown() {
    setShowDropdown(!showDropdown);
    onSearch?.('');
  }

  // Closing the dropdown when clicked outside or when clicked on child node except search
  function handleClick(e: MouseEvent) {
    if (!dropdownRef.current?.contains(e.target as Node)) {
      setShowDropdown(false);
    } else if (contentRef.current?.contains(e.target as Node) && !inputRef.current?.isEqualNode(e.target as Node)) {
      setTimeout(() => {
        setShowDropdown(false);
      }, 200);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!contentRef.current || !dropdownRef.current) return;
    setContentHeight(contentRef.current.clientHeight + dropdownRef.current.clientHeight);
    setContentWidth(contentRef.current.clientWidth);
  }, [contentRef, dropdownRef, showDropdown, contentRef.current?.clientWidth]);

  return (
    <div className="max-w-full" ref={dropdownRef}>
      <div
        id={id}
        onClick={() => toggleDropdown()}
        className={`inline-flex justify-center gap-2 items-center w-full truncate text-lg bg-transparent rounded-md focus:outline-none cursor-pointer ${
          disablePadding ? '' : 'px-4'
        }`}>
        <span className="truncate">{displayName}</span>
        {showArrow && <FontAwesomeIcon icon={showDropdown ? faChevronUp : faChevronDown} className="w-3 h-3" />}
      </div>
      {showDropdown && (
        <div
          ref={contentRef}
          id="dropdown-menu"
          className={`absolute max-h-[500px] min-w-[200px] max-w-[60vw] h-fit overflow-y-scroll rounded-md shadow-lg bg-[#001c38] ring-1 ring-black ring-opacity-5 p-1 space-y-1 ${dropdownClassName} break-all`}
          style={{
            marginTop: position === 'top-left' || position === 'top-right' ? `-${contentHeight}px` : '',
            marginRight: position === 'top-right' || position === 'bottom-right' ? `${contentWidth}px` : '',
            marginLeft: position === 'top-left' || position === 'bottom-left' ? `-${contentWidth}px` : '',
          }}>
          {onSearch && (
            <input
              ref={inputRef}
              id="search-input"
              className="block w-full px-4 py-2 text-gray-800 bg-gray-100 border rounded-md focus:outline-none"
              type="text"
              placeholder={t('common:search')}
              onChange={(e) => onSearch?.(e.target.value?.toLowerCase())}
            />
          )}
          {children}
        </div>
      )}
    </div>
  );
};
