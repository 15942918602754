import { useSelector } from '../../../reduxToolkit/typedSelector';
import { createRecording, stopRecording } from '../../../services/services';

export function useRecording() {
  const meeting = useSelector(({ video }) => video.meeting);
  const meetingChannel = useSelector(({ common }) => common.pusherChannels.meetingChannel);
  const archiving = useSelector(({ video }) => video.archiving);

  async function setupPusher() {
    if (!meetingChannel) {
      console.log('Could not setup recording pusher events!');
      return;
    }
  }

  async function toggleRecording() {
    if (!archiving) {
      startRecording();
    } else {
      cancelRecording();
    }
  }

  async function startRecording() {
    try {
      await createRecording(meeting?._id!, '');
    } catch (err) {
      console.error(err);
    }
  }

  async function cancelRecording() {
    try {
      await stopRecording(meeting?._id!);
    } catch (err) {
      console.error(err);
    }
  }

  return { archiving, startRecording, cancelRecording, toggleRecording, setupPusher };
}
