import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'next-i18next';
import { COMPONENTFILTER } from '../components/account/event/Homepage/Functions/QuickFilter';
import { QueueInviteStatus } from '../utils/enums';
import { AgendaComponentInterface, AgendaEntry, ALL_COMPONENTS_INTERSECTION_TYPE, SpeakerEntry, SupportedPagebuilderThemes } from './pagebuilder';

export interface FadableElement {
  visible: boolean;
  name: string;
}

export interface EventTemplate {
  customBorderRadius: string;
  customFont: string;
  showEventLogout: boolean;
  signedUpSuccessSentenceCustom: string;
  isPublished?: boolean;
  slugDraft?: string;
  individualNames: {
    emailReplyTo: string;
    homeTooltip: string;
    subBooths: string;
    emailSenderName: string;
    username: string;
    meetingFullNoticeText: string;
    deviceSelectionText: string;
    filter: string;
    myEventPage: string;
    appointments: string;
    pdfUploadsInBooth: string;
    contactShare: string;
    meetingrooms: string;
    conferencerooms: string;
    presentationrooms: string;
    orientationMode: string;
    orientationModeTooltip: string;
    linkedPeople: string;
  };
  signupButtonText?: string;
  gradientOnImages: boolean;
  showTicketGate: boolean;
  uiTemplate: string;
  complementColor: string;
  complementButtonTextColor: string;
  logo: string;
  favicon: string;
  mainColor: string;
  mainButtonTextColor: string;
  oneToOneBackgroundImage: string;
  presentationBackgroundImage: string;
  oneToOneSubPageBackgroundImage: string;
  presentationSubPageBackground: string;
  amountOfCurtains: number;
  curtainSettings: any;
  logoHeight: string;
  showUserCount: boolean;
  showHostedBy: boolean;
  showUsernameRenameField?: boolean;
  useSpotlight?: boolean;
  signup: {
    // infoBackgroundColor: string;
    // infoFontColor: string;
    prePrivacyText: string;
    showDefaultDataProtectionNotice: boolean;
    dataPrivacySentence?: string;
    dataPrivacySentenceTranslations: CustomTranslation[];
  };
  live: {
    logoHeight: number;
    showEventName: boolean;
    showLogo: boolean;
    showVideoControls: boolean;
    showWelcomeSentence: boolean;
    customWelcomeSentence?: string;
    customEventDisplayName?: string;
  };
  landing: {
    showLogo?: boolean;
    showName?: boolean;
    showEndTime?: boolean;
    customWelcomeSentence?: string;
    customEventDisplayName?: string;
  };
  stackedPresentations: boolean;
  // mbw specific
  secondaryLogo?: string;
  emailTemplate: EmailTemplateTemplate;
}

export type EventStartTemplate = 'ticketshop' | 'stream';
export interface EventInterface {
  hasBetaFeatures: boolean;
  lock: { datetime_locked: Date; locked_by: User };
  displayname?: string;
  eventType?: 'hybrid' | 'digital';
  _id: string;
  isPublished?: boolean;
  datetime_created: string;
  datetime_end: string;
  datetime_start: string;
  organization: Organization;
  eventRole: ('host' | 'exhibitor')[];
  private: boolean;
  name: string;
  slug: string;
  description: string;
  region?: string;
  status: string;
  signupsuccesspage: any;
  landingpage?: any;
  languages: string[];
  isFormal: boolean;
  showOnboardingToastbar?: boolean;
  legal: {
    imprintUrl?: string;
    dataPrivacyUrl?: string;
    termsUrl?: string;
    hideTerms?: boolean;
    hideImprint?: boolean;
  };
  eventauth: {
    provider: string;
    mustRegister: boolean;
    registerPageUrl: string;
  };
  template: EventTemplate;
  videomodes: {
    meeting: {
      active: boolean;
      version: string;
    };
    presentation: {
      active: boolean;
      version: string;
    };
    conference: {
      active: boolean;
      version: string;
    };
    networking: {
      active: boolean;
      version: string;
    };
    breakout: {
      active: boolean;
      version: string;
    };
  };
  settings: {
    allowSearchEngineIndex?: boolean;
    skipLandingPage: boolean;
    presentations: {
      blockTime?: number;
      waitingRoomActive?: boolean;
      autoRecordSlots?: boolean;
    };
    allowMessagingOtherAttendees: boolean;
    allowAppAccessBeforeEventStart: boolean;
    allowAccessToLive: boolean;
    showBoothsWithoutMeetingroom: boolean;
    showMeetingtime: boolean;
    showBoothStats: boolean;
    showBoothPotentialQueueUsers: boolean;
    hasNotifications: boolean;
    allowMobileBrowser: boolean;
    enableMobileBrowserWarning: boolean;
    queuemode: string;
    allowUserRenameBooth: boolean;
    autoRedirFromLanding: boolean;
    autoDisableEventAccess: boolean;
    disableEventAccessAfter: boolean;
    redirectDisabledEvent: boolean;
    eventAccessDisabledPage: string;
    maxBoothPdfUpload: number;
    allowUserFileupload?: boolean;
    maxUserFileUpload: number;
    userFileUploadDescription: string;
    showAppNotice: boolean;
    showAppNoticeAndroid: boolean;
    showAppNoticeIos: boolean;
    skipLive: boolean;
    useBoothNumber: boolean;
    orientationMode?: boolean;
    suggestContactShare: boolean;
    allowFileUploadInMeeting: boolean;
    showQueueIndicator: boolean;
    preMeetingDeviceSelection: { video: boolean; audio: boolean; chat: boolean };
    timezone?: string;
    orientationDefaultOn?: boolean;
    alowDirectTicketcodeLogin?: boolean;
    allowPresenceTracking?: boolean;
    allowAttendeeMessaging?: boolean;
    preventImageOptimization: boolean;
    allowBoothLikes?: boolean;
    privateModeQA?: boolean;
    allowOnlyOneActiveTab?: boolean;
    allowMessagingBoothVisitors?: boolean;
    predifinedRegistrationFeedbacks?: string[];
    presentationCountdownEnabled?: boolean;
    presentationCountdownMinutes: number;
    allowMessagingRegistrationFilters?: boolean;
    showAdminsInMessagingCenter?: boolean;
    showToggleShowAdminsInMessagingCenter?: boolean;
    allowEventPasswordLogin?: boolean;
    showLastSenderPrefix?: boolean;
    allowMessagingEmailInfo?: boolean;
    enableOnlinePresentationBooking?: boolean;
    enablePresentationFavorite?: boolean;
    incompleteProfile?: {
      active?: boolean;
      welcomeText?: string;
      logo?: string;
    };
    vystemDisabledFeatures: string[];
    isLinkedBoothMembersActive?: boolean;
    showChildBooths?: boolean;
    youthProtection?: {
      active: boolean;
    };
  };
  studios?: Meetingroom[];
  modules: {
    [key: string]: any;
    contactshare: {
      active: boolean;
    };
    app: {
      active: boolean;
    };
    livestreams: {
      active: boolean;
      settings: {
        canSelectStreamProvider: boolean;
      };
    };
    guestmanagement: {
      active: boolean;
      settings: {
        noDuplicatesInLimitedAndAtLeastOneLimited: boolean;
        attachTicketPDF: boolean;
        attachWallet: boolean;
        attachICS: boolean;
        autoApproveUser: boolean;
        linkedInAuth: boolean;
        registrationFields: RegistrationField[];
        authWithoutEmail: boolean;
        authRandomUser: boolean;
        allowEditingProfileFieldValues: boolean;
        additionalFields: [
          {
            fieldtype: string;
            label: string;
            hidden: boolean;
            isRequired: boolean;
          }
        ];
        requireEmailConfirm: boolean;
        signupAfterEventPassed: boolean;
        altSignupAfterEventPassed: boolean;
        maxRegistrationsPerUser?: number;
        authUserAfterSignupWhenPossible?: boolean;
        guestValidationAutoUpdate?: boolean;
        onlyOneEmailSendPerUser?: boolean;
        ticketPDFSettings?: {
          showCustomizableVersion?: boolean;
          ticketBorderColor?: string;
          ticketTextColor?: string;
          ticketBackgroundColor?: string;
          ticketBackgroundImage?: string;
          showCustomDate?: boolean;
          customStartDate?: Date;
          customEndDate?: Date;
          showGuestName?: boolean;
          guestFirstName?: string;
          guestLastName?: string;
        };
        confirmationEmailSettings?: {
          confirmationEmailMode?: 'auto' | 'manual' | 'field_based';
          selectedField?: string;
          triggerValue?: string;
        };
        sendCancellationEmail?: boolean;
      };
    };
    templatemanagement: {
      active: boolean;
      settings: {
        templateSelection: boolean;
        templateEditor: boolean;
      };
    };
    customTracking: {
      active: boolean;
      settings: {
        customScript: string;
        matomoUrl: string;
        matomoSiteId: string;
        fbCode: string;
        googleTagManager: string;
      };
    };
    challenges: {
      active: boolean;
    };
    studios: {
      active: boolean;
    };
    analytics: {
      active: boolean;
    };
    qacontrol: {
      active: boolean;
    };
    preuploadedmedia: {
      active: boolean;
      settings: {
        boothVideoUpload: boolean;
      };
    };
    advancedFilters: {
      active: boolean;
      settings: {
        allowSelfSelectValues: boolean;
      };
    };
    directorsControl: {
      active: boolean;
    };
    userReporting: {
      active: boolean;
      settings: {
        autoblock: {
          active: boolean;
          threshold: number;
        };
      };
    };
    feedbackEditing: {
      active: boolean;
    };
    networking: {
      active: boolean;
      settings: {
        roomlimit: number;
        participantlimit: number;
      };
    };
    booking: {
      active: boolean;
      settings: {
        start_time: number;
        end_time: number;
        defaultBooth: string;
      };
    };
    photobooths: {
      active: boolean;
      settings: {
        autoApprove: boolean;
      };
    };
    tickets: {
      settings: {
        allowSkipBillInformation?: boolean;
        sendMailConfirmationForUnfinishedOrderOncePaid?: boolean;
        useEUTaxRuleForBusiness: boolean;
        personalizeBeforeBuying: boolean;
        taxRule: number;
        hidePrivateCustomer: boolean;
        autoGenerateInvoice: boolean;
        invoiceGenerationAndSending: 'withOrder' | 'afterPayment' | 'manual';
        invoiceTemplate: InvoiceTemplate;
        emailMessage?: string;
        showDiscountCodeOnInvoice?: boolean;
        priceType?: 'net' | 'gross';
        enableGroupTicket?: boolean;
      };
      fees: {
        pricePerTransaction: number;
        volumePercentage: number;
      };
    };
    privileges: { active: boolean };
    recording: { active: boolean };
  };
  booths?: Booth[];
  pages: PageData[];
  challenges?: Challenge[];
  hybridSettings: {
    allowClickIn: boolean;
    allowClickOut: boolean;
    lockOnCheckin: boolean;
    enableCovidQrscan: boolean;
    daysSinceLastVaccination: number;
    minimumCountVaccinations: number;
    automaticCheckinOnSuccess: boolean;
  };
  eventPassword?: string;
  integrations: {
    // here we store data about the sync with integrations
    eventbrite: {
      eventbrite_event: string;
      importWithId: string;
      sendTicketEmails: boolean;
      paused: boolean;
    };
    webcode: string;
  };
  payoutInformation?: PayoutInformation;
  payoutRequests?: PayoutRequest[];
  eventStartTemplate?: EventStartTemplate;
  componentFilter: COMPONENTFILTER[];
  proAnalytics?: boolean;
}

export interface PayoutInformation {
  _id?: string;
  event: string;
  accountType: string;
  firstnameOfAccountOwner: string;
  lastnameOfAccountOwner: string;
  address: string;
  addressSupplement: string;
  nameOfBank: string;
  iban: string;
  bic: string;
}

export interface PayoutRequest {
  event: string;
  status: requestStatus;
  amount: number;
  feedback: string;
  datetime_created: Date;
}

export type requestStatus = 'new' | 'inprogress' | 'denied' | 'confirmed';

export type presentationSlotTypes = 'online' | 'on-site' | 'hybrid';

export interface Meetingroom extends Record<string, any> {
  meetingroomid?: string;
  boothid?: string;
  booth: Booth;
  contactperson: string;
  contactposition: string;
  isStudioForPresentationSlot?: boolean;
  datetime_created: string;
  datetime_deleted: string | null;
  description: string;
  event: any; //TODO Polymorph this
  exhibitor: string;
  meetingimage: string;
  parentMeetingId: string | undefined;
  presentationBackground?: string | null;
  presentationSpotlight?: string | null;
  meetingtype: 'presentation' | 'meeting' | 'temporary' | 'conference' | 'networking' | 'breakout' | 'studio';
  name: string;
  vcard: any;
  settings: {
    showBreakoutRoom: boolean;
    onlyAdminDisplayedBig: boolean;
    showLikeButton: boolean;
    allowDeepSettings: boolean;
    allowJoinViaFrontend: boolean;
    joinwithoutadmin: boolean;
    showRecordingButton: boolean;
    datetimeRecordingStart: Date;
    userlimit: number;
    visible: boolean;
    usermustknock: boolean;
    contactShareEnabled: boolean;
    userScreenshareEnabled: boolean;
    hasChatComponent: boolean;
    participantListUserEnable: boolean;
    hasControlComponent: boolean;
    showUserList: boolean;
    canHaveBreakoutRooms: boolean;
    breakoutroomsLocked?: boolean;
    usesPassword: boolean;
    showBrowseEvent: boolean;
    raiseHandEnabled: boolean;
    showEventOptions: boolean;
    enableResizeOnDoubleClick: boolean;
    allowChatHighlights: boolean;
    showBlackVideoTiles: boolean;
    playJoinSound: boolean;
    pollEnabled: boolean;
    guestDevicesEnabled: boolean;
  };
  status: {
    canjoin: boolean;
    islive: boolean;
    lastjoin: string;
  };
  template: {
    title: string;
    headerEnabled: boolean;
    headerImage: string;
    backgroundColor: string;
    logo: string;
    tileBackgroundColor: string;
    hasNameplates: string;
    showKnockViewDescription: boolean;
  };
  displaystatus: string;
  _id: string;
  image: string;
  slot: Slot;
  subtitle?: string;
  subtext?: string;
  meetingappointments: any[];
  blocktime?: number;
  vonageSessionId?: string;
  //Studio Related
  liveStream: string;
  broadcastId: string | null;
  archiveId: string | null;
  thumbnail: string | null;
  qaPageId: string | null;
  meetingPassword?: string;
  studioPassword?: string;
  presentationSlotType?: presentationSlotTypes;
  presentationMaxSeats?: number;
  presentationRequireSignup?: boolean;
  isSimulcast?: boolean;
  canchat?: boolean;
}

export interface InvoiceTemplate {
  logo: string | null;
  logoWidth: number;
  senderDataHTML: string;
  footerHTML: string;
  showInvoiceNumber: boolean;
  invoiceNumberStart: number;
  invoicePrefix: string;
  midTextHTML?: string;
  showCommentField?: boolean;
}

export interface VCardModalData {
  meetingroomId?: string | null;
}

export interface PDFFile {
  _id: string;
  file: string;
  name: string;
}

export interface MessagingFile {
  _id: string;
  name: string;
  file_type: string;
}

export interface Organization {
  organizationid: string;
  backgroundImage: string;
  description: string;
  externalIdentifier: string;
  headerImages: string[];
  logo: string;
  slogan: string;
  isFreeTier?: boolean;
  welcomeVideo: string;
  welcomeVideoType: string;
  datetime_created: string;
  booths: Booth[];
  name: string;
  _id: string;
  category: string;
  offer: string;
  teaser: string;
  pdf: string;
  pdfUploads: PDFFile[];
  accountPreference: 'exhibitor' | 'organizer';
  hideEventOptions: {
    pastEvents: boolean | undefined;
    noPrivileges: boolean | undefined;
  };
  defaultBackground?: string;
  allowAppCreateGuest: boolean;
  allowExceedingBudget: boolean;
  customDomain?: string;
  allowedFonts: string[];
  mainColor?: string;
  mainButtonTextColor?: string;
  integrations: {
    eventbrite: { active: boolean; token: string };
    intercom: {
      showIntercomSupport: boolean;
      intercomId?: string;
      intercomAndroid?: string;
      intercomIos?: string;
    };
  };
  users: User[];
  memberships: any[];
  accountpreference: 'organizer' | 'exhibitor';
  platformPermissions: {
    resetSubUsersPassword: boolean;
    addSubUsersToExhibitors: boolean;
  };
  supportedPagebuilderThemes: SupportedPagebuilderThemes[];
  defaultHeader: string;
  defaultEventLogo?: string;
  customUpperTextColor?: string; // bayme
  onlyAccessEventWithPermissions: boolean;
  onlyAccessBoothWithPermissions: boolean;
  customFooterLinks: { label: string; link: string }[];
  memberCanCreateEvents: boolean;
  legal?: {
    dataPrivacyUrl?: string;
    imprintUrl?: string;
  };
  algoliaKey?: string;
}

export type RoleName = 'PLATFORM_ADMIN' | 'ORGANIZATION_ADMIN' | 'PARTICIPANT_ANONYM' | 'ORGANIZATION_EXTERNAL_SPEAKER' | 'ORGANIZATION_SUBUSER';

export enum RoleNames {
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  ORGANIZATION_SUBUSER = 'ORGANIZATION_SUBUSER',
  ORGANIZATION_EXTERNAL_SPEAKER = 'ORGANIZATION_EXTERNAL_SPEAKER',
}

export interface User {
  datetime_created: string;
  datetime_deleted: Date;
  email: string;
  name: string;
  pushTokens: {
    web: string[];
  };
  organization?: Organization;
  lastSeenVersionInfo?: string;
  role: RoleName;
  _id: string;
  resettoken?: string | null;
  emailOptIn: boolean;
  orgmemberships: any;
  assignedMeetingroom?: string;
  meetingassignments: any;
  avatar?: any;
  registrations?: EventRegistration[];
  datetimeLastRealtimeConnect?: Date;
  datetimeLastRealtimeDisconnect?: Date;
  currentMeeting?: string;
  profileStatus?: { emoji?: string; text?: string };
  registrationFields?: any;
  isConfirmed?: boolean;
  hasRequestedAccountUpgrade?: boolean;
  lastSeenAd?: number;
}
export type TeamMember = Omit<User, 'organization'> & { organization: string };

export interface UsersList {
  user: User;
  event: Event;
}

export interface TextValueOption {
  content: string;
  conditionalRequiredFields: any[];
  limit?: number;
  limitWarning?: string;
  translations?: CustomTranslation[];
  sortIndex: number;
  isCustom?: boolean;
  id?: string;
  _id?: string;
  cardImage?: string | null;
  additionalInformation?: string | null;
}

export interface RegistrationField {
  canEditInProfile: boolean;
  fieldType: string;
  label: string;
  helperText?: string;
  isHidden: boolean;
  valueOptions: string[];
  labelTranslations?: CustomTranslation[];
  helperTextTranslations?: CustomTranslation[];
  textValueOptions: TextValueOption[];
  _id: string;
  datetime_deleted: null | Date;
  isRequired: boolean;
  isUserName: boolean;
  isEmailField: boolean;
  isPublic: boolean;
  isSuffix: boolean;
  sortIndex: number;
  canMultiSelect: boolean;
  canEditInGuestMgmt: boolean;
  visibleToExhibitors: boolean;
  isIncludedInContactshare: boolean;
  selectDisplayMode: 'card' | 'dropdown';
  validation?: {
    min?: {
      value?: number;
      message?: string;
    };
    max?: {
      value?: number;
      message?: string;
    };
    pattern?: {
      value?: string;
      message?: string;
    };
  };
  trueText?: string;
  falseText?: string;
  variableUseCount?: number;
  parentField?: FieldValuePair;
  showInUserProfile: boolean;
  value?: string;
}

export interface FieldValuePair {
  field?: string;
  value?: string;
}

export interface EventRegistration {
  event: string;
  providertoken: string;
  provider: string;
  registrationFields: { value: string; field: RegistrationField }[];
  possibleRegistrationFields: RegistrationField[];
  status: string;
  _id: string;
  qrcode?: string;
  wallet?: string;
  ticket?: string;
  ticketcode?: string;
  signup?: {
    name: string;
    event: string;
    registrationFields: any;
    _id: string;
  };
  datetime_locked: Date | null;
  user?: User;
  reviewFeedback?: string | null;
  datetime_review_feedback_created?: Date | null;
  datetime_user_updated?: Date | null;
  datetime_created?: Date | null;
  datetime_accepted?: Date | null;
  datetime_cancelled?: Date | null;
  datetime_rejected?: Date | null;
  lastCheckStatus?: any;
  messagingGhostMode?: boolean;
  messagingAllowNotifications?: boolean;
  currentActiveTab?: string | null;
  datetime_deleted?: Date | null;
  hasSeenIncompleteProfileModal?: boolean;
  ticketOrderNumber?: string;
  ticketOrder?: string;
  tickettype?: {
    name?: string;
  };
  isMainGroupTicketHolder?: boolean;
  isConfirmationEmailSent?: boolean;
  datetimeLastRealtimeConnect: Date | null;
  datetimeLastRealtimeDisconnect: Date | null;
  lastStatus?: 'checkin' | 'checkout';
  utm_source?: string;
}

export interface EventRegistrationPopulated extends Omit<EventRegistration, 'event' | 'ticketOrder'> {
  event: EventInterface;
  ticketOrder?: Partial<TicketOrder>;
}

export type VideoType = 'recording' | 'youtube' | 'vystemboothvideo';

export interface WelcomeVideo {
  type: VideoType;
  video: string;
  videoApiId?: string;
  videoApiHls?: string;
  thumbnail?: string | null;
  isNew?: boolean;
  muxId?: string;
  muxPlaybackIds?: [
    {
      id: string;
      policy: 'public' | 'signed';
    }
  ];
  autoplay: boolean;
  booth?: string;
  datetime_deleted?: string | null;
  _id?: string;
}

export interface Booth {
  settings: {
    enableAppointments: {
      allowSelfSelectValues: boolean;
      value: boolean;
    };
    displayPresentationsInBooths: {
      allowSelfSelectValues: boolean;
      value: boolean;
    };
    displayContactShareInBooth: {
      allowSelfSelectValues: boolean;
      value: boolean;
    };
    displayNetworkingroomsInBooth: {
      value: boolean;
    };
    externalSpeakers: {
      isEnabled: boolean;
      canExternalSpeakerEdit: boolean;
    };
    spotlight: {
      isEnabled: boolean;
    };
    canEditMeetingSettings: {
      value: boolean;
    };
    showBoothStats: {
      value: boolean;
    };
    showAutoReply: {
      value: boolean;
    };
    enableAppointmentExhibitorEmail: {
      value: boolean;
    };
    autoSlideshow: {
      value: boolean;
    };
    showLikesCounter: {
      value: boolean;
    };
    showLikeInEventHome: {
      value: boolean;
    };
  };
  autoReply: {
    active: boolean;
    settings: {
      text: string;
      delayMinutes: number;
    };
  };
  datetime_hostinvite_sent?: Date;
  boothid?: string;
  datetime_created: string;
  allowedQueueForwarding: string;
  selectedQueueForwardingBooths: string[];
  event: EventInterface;
  organization: Organization;
  meetingrooms: Meetingroom[];
  conferencerooms: Meetingroom[];
  presentationrooms: Meetingroom[];
  networkingrooms: Meetingroom[];
  categoryFilterValues: string[];
  _id: string;
  hasUnlimitedRooms: boolean;
  maxMeetings: number;
  maxPresentations: number;
  maxConferences: number;
  maxPdfUploads: number;
  boothnumber?: string;
  allowExternalSpeakers?: boolean;
  name: string;
  offer?: string;
  teaser?: string;
  slogan?: string;
  sortIndex?: number;
  description?: string;
  welcomeVideo?: string;
  boothVideos?: WelcomeVideo[];
  welcomeVideoType?: string;
  category?: string;
  logo?: string | null;
  headerImages?: string[];
  backgroundImage?: string | null;
  spotlightBackgroundImage?: string | null;
  pdfUploads?: PDFFile[];
  websiteUrl?: string;
  parentBooth?: string | Booth | null;
  childBooths?: Booth[];
  isSpotlight?: boolean;
  calculatedMeetingMinutes?: number;
  favouriteBoothsCount: number;
  linkedPeople?: LinkedPerson[];
  skippedSteps?: {
    logo: boolean;
    backgroundImage: boolean;
  };
}

export interface LinkedPerson {
  user: Pick<User, '_id' | 'avatar' | 'name' | 'datetimeLastRealtimeConnect' | 'datetimeLastRealtimeDisconnect'> & { organization: string };
  avatar?: string;
  name?: string;
  description?: string;
  _id: string;
}
export interface Slot {
  datetime_hostnotification_sent?: string;
  datetime_end: string;
  datetime_start: string;
  meetingroom: Meetingroom;
  status?: string;
  sortIndex: number;
  _id: string;
  ticketCount?: number;
}

export interface BoothContactShareCount {
  _id: string;
  name: string;
  count: number;
}

export interface FavoriteSlot {
  datetime_created: string;
  event: string;
  slot: Slot;
  user: string;
  _id: string;
}

export interface FavouriteBooth {
  _id: string;
  user: string;
  booth: Booth;
  datetime_created: string;
}

export interface BoothLikesCount {
  booth: string;
  count: number;
}

export interface DataShare {
  attendeeid: string;
  datetime_created: string;
  email: string;
  meetingroomJoin: Meetingroom;
  meetingroomid: string;
  meetingroom?: Meetingroom;
  message: string;
  name: string;
  notes: Note[];
  files: any[];
  _id: string;
  registration: EventRegistration;
}
export interface Status {
  eventid: string;
  userid: string;
  event: Event;
  user: User;
}

export interface Note {
  dateshareid: string;
  datetime_created: string;
  datetime_updated: null;
  note: string;
  _id: string;
}

export interface AdvancedFilter {
  categoryFilterValues: CategoryFilterValues[];
  datetime_created: string;
  datetime_deleted: string;
  event: string;
  isDefaultFilter: boolean;
  isMainFilter: boolean;
  isHiddenFilter: boolean;
  isUserVisible: boolean;
  isUserFilter: boolean;
  name: string;
  _id: string;
}

export interface CategoryFilterValues {
  childCategoryFilter: string[];
  datetime_created: string;
  datetime_deleted?: string;
  parentCategoryFilter: string;
  value: string;
  _id: string;
}

export interface Role {
  description: string;
  level: number;
  name: RoleName;
}

export type MailStatus = 'processed' | 'dropped' | 'open' | 'bounce' | 'delivered' | 'deferred';

export interface MailReport {
  count: number;
  status: MailStatus;
}

export interface UserCacheObject {
  user: {
    name: string;
    email: string;
    _id: string;
  };
  attendee?: {
    meetingid: string;
    role: string;
  };
}

export interface NetworkProps {
  name: string;
  subtitle?: string;
  subtext?: string;
  participantlimit?: number;
  booth?: string;
  description?: string;
  image?: string;
  joinwithoutadmin?: boolean;
  bannerImage?: string;
}

export interface NetworkUser {
  _id: string;
  name: string;
  avatar: string;
  organization: string;
}
export interface Network {
  canchat: boolean;
  description?: string;
  datetime_created: string;
  displaystatus: string;
  event: EventInterface;
  booth: any;
  image: string;
  meetingtype: string;
  name: string;
  settings: {
    visible: boolean;
    userlimit: number;
    joinwithoutadmin: boolean;
    usermustknock: boolean;
  };
  assignedusers: any[];
  status: { lastjoin: string; canjoin: boolean; islive: boolean; isalive: boolean };
  users: NetworkUser[];
  _id: string;
  subtitle: string;
  subtext: string;
  bannerImage?: string;
}

export interface MeetingChatMessage {
  _id: string; // Optional ID field for Mongoose document
  message: string;
  user: User;
  meeting?: string;
  page?: string;
  replyTo?: Partial<MeetingChatMessage>;
  datetime_created: string;
  datetime_deleted: string;
  isHighlighted: boolean;
  eventId?: string;
  isSeen?: boolean;
}

export interface ContactShare {
  datetime_created: string;
  email: string;
  meetingroomid: Meetingroom;
  boothid: Booth;
  message: string;
  name: string;
  userid: string;
  _id: string;
  note: MeetingNote;
}

export interface MeetingNote {
  _id: string;
  datetime_created: string;
  note: string;
}

export interface Appointment {
  booth: Booth;
  datetime_end: string;
  datetime_start: string;
  meetingroom: Meetingroom;
  organization: Organization;
  user: User;
  created_by: string;
  _id: string;
}

export enum AppointmentStateEnum {
  NOT_BOOKED,
  BOOKED_BY_USER,
  AVAILABLE,
  CAN_UNBOOK,
  NOT_AVAILABLE,
  NONE,
  CREATED_BY_SELF,
}

export interface LiveStreamAsset {
  _id: string;
  assetId: string;
  playbackId: string;
  hls: string;
  isMp4Ready: boolean;
  isReady: boolean;
  datetime_created: Date;
  datetime_recording_start: Date;
  static_assets: [
    {
      name: string;
    }
  ];
}

export interface LiveStream {
  _id: string;
  event: string | EventInterface;
  liveStreamId: string;
  streamKey: string;
  rtmpUrl: string;
  name: string;
  isBroadcasting: boolean;
  isRecording: boolean;
  isEncoderConnected: boolean;
  iframe: string;
  player: string;
  hls: string;
  thumbnail: string;
  datetime_created: string;
  isStudioLiveStream: boolean;
  liveStreamType: string;
  assets: LiveStreamAsset[];
  simulcast: Simulcast[];
}

export interface Simulcast {
  id: string; // simulcast id
  passthrough?: string;
  stream_key?: string;
  url: string;
  status: 'idle' | 'starting' | 'errored' | 'broadcasting';
}

export interface BaseTemplate {
  _id: number;
  text?: string;
  value?: string;
  description?: string;
  visible?: boolean;
  templateLanguage?: 'de' | 'en' | 'fr' | 'all';
  // text variables that has no baseTemplate are shown all time, enable this to hide them
  hideRegistrationVariables?: boolean;
  sectionTitle?: string;
  sectionDescription?: string;
}

export interface EmailVariable {
  text: string;
  value: string;
  description?: string;
  baseTemplate?: string[];
  condition?: boolean;
  isRegistrationRequired?: boolean;
}

export interface socialShareIconsType {
  url: string;
  icon: string;
  urlEncode: boolean;
  customSvgObject?: string;
}

export type EmailTemplateType = 'default' | 'editor';

export interface EmailTemplate {
  _id: string;
  event: Event;
  baseTemplate: string;
  templateLanguage?: 'de' | 'en' | 'all';
  hasCustomDesign: boolean;
  isActive: boolean;
  name: string;
  template: EmailTemplateTemplate;
}

export interface EmailTemplateTemplate {
  metatitle: string;
  textVersion: string;
  top: {
    visible?: boolean;
    title: string;
    showLogo: boolean;
    logoUrl: string;
  };
  heroImage: {
    visible: boolean;
    backgroundImageUrl: string;
    title: string;
    subtitle: string;
  };
  main: {
    title: string;
    description: string;
    postActionsText: {
      visible?: boolean;
      text?: string;
    };
    socialShareIcons: socialShareIconsType[];
    ctaButton: {
      visible: boolean;
      text: string;
      url: string;
    };
    ticketCode: {
      visible: boolean;
      text: string;
    };
    digitalTicketButton: {
      visible: boolean;
      text: string;
    };
    qrCode: {
      visible: boolean;
    };
  };
  footer: {
    dataPrivacyText: string;
    dataPrivacyUrl: string;
    imprintText: string;
    imprintUrl: string;
    unsubscribeText: string;
    unsubscribeUrl: string;
  };
  style: {
    mainColor: string;
    mainTextColor: string;
    secondaryColor: string;
    secondaryTextColor: string;
    top: {
      logoHeight: number;
      backgroundColor: string;
      textColor: string;
    };
    heroImage: {
      height: number;
      overlayColor: string;
      showOverlayColor: boolean;
      backgroundFallbackColor: string;
    };
    main: {
      backgroundColor: string;
      textColor: string;
      ctaButton: {
        backgroundColor: string;
        color: string;
      };
      socialShareIconsBgColor: string;
      socialShareIconsIconColor: string;
      socialShareIconsBgHoverColor: string;
      socialShareIconsIconHoverColor: string;
    };
    footer: {
      backgroundColor: string;
      textColor: string;
      linkColor: string;
    };
  };
}

export interface PhotoBooth {
  event: Event;
  datetime_created: string;
  user: User;
  file: string;
  approved: boolean;
  _id: string;
}

export interface ChallengeSubmission {
  user: User;
  score: any;
  Challenge: Challenge;
}

interface ChallengeTask {
  _id: string;
  name: string;
  description?: string;
  taskType: string;
  goal: number;
}
export interface Challenge {
  _id: string;
  name: string;
  description: string;
  isActive: boolean;
  isSequential: boolean;
  event: string;
  tasks: ChallengeTask[];
  winner: User;
  submissions: ChallengeSubmission[];
}

export interface PresentationMeetingAnalytics {
  duration: number;
  minutesInMeeting: number;
  meetingid: string;
  user: User;
  userid: string;
}

export interface BroadcastInterface {
  _id: string;
  message: string;
  event: string;
  datetime_created: string;
  datetime_expiry?: string;
  type: string;
  isDraft: boolean;
}

export interface FeedbackInterface {
  _id: string;
  event: string;
  form: any[];
  answers: any[];
  name: string;
}

export interface AltSignup {
  _id?: string;
  name?: string;
  event?: string;
  registrationFields?: RegistrationField[];
  requiredFields?: RegistrationField[];
  displayName?: string;
  registrationEmailTemplates?: {
    [key: string]: string;
  };
  showHeader?: boolean;
  headerImage?: string;
  styling?: {
    backgroundImage?: string;
    backgroundColor?: string;
    backgroundBlur?: number;
    overlayColor?: string;
    overlayOpacity?: number;
    textColor?: string;
    fieldsColor?: string;
    fieldsTextColor?: string;
  };
}

export interface AppointmentState {
  isInviting: boolean;
  entries: AppointmentEntry[];
  currentAppointment: AppointmentEntry | null;
}
export interface UpcomingAppointment {
  show: boolean;
  onConfirm: Function;
}
export interface AppointmentEntry {
  realtimeStatus: 'online' | 'offline';
  _id: string;
  user: {
    _id: string;
    name: string;
    avatar?: string;
  };
  meetingroom: string;
  datetime_start: Date;
  datetime_end: Date;
}

export enum DrawerPrivileges {
  // drawer menu items starts here
  details,
  pages,
  booths,
  'booths.overview',
  'booths.meetingrooms',
  'booths.sorting',
  'booths.filters',
  'booths.exhibitorInformation',
  'booths.settings',
  /*overview,
  'overview.exhibitorInformation',
  'overview.settings',*/
  presentations,
  'presentations.slots',
  'presentations.settings',
  livestreams,
  studios,
  media,
  guestmanagement,
  'guestmanagement.overview',
  'guestmanagement.guestcommunication',
  'guestmanagement.profilefields',
  'guestmanagement.guestValidation',
  'guestmanagement.alternativeSignup',
  'guestmanagement.accessRule',
  'guestmanagement.settings',
  'guestmanagement.infectionTracking',
  organizer,
  individualWatermarking,
  tracking,
  emails,
  advancedConfiguration,
  'advancedConfiguration.overview',
  'advancedConfiguration.hybridEvent',
  'advancedConfiguration.individualName',
  'advancedConfiguration.messaging',
  'advancedConfiguration.features',
  'advancedConfiguration.changelog',
  directorControl,
  hybrid,
  'hybrid.analytics',
  'hybrid.control',
  qacontrol,
  reports,
  poll,
  feedback,
  analytics,
  output,
}

export enum PlatformPrivileges {
  dashboard,
  checkin,
  clickin,
  guestlist,
  pagebuilder,
}

export enum EmbeddedPrivileges {
  'details.embedded.lockEvent',
}

export const PrivilegeTypes = { ...DrawerPrivileges, ...PlatformPrivileges, ...EmbeddedPrivileges };

export interface Privilege {
  _id: string;
  event: string;
  user: User;
  settings: {
    [key in keyof typeof PrivilegeTypes]: {
      accessible: boolean;
      embedded: { [key: string]: boolean };
      [key: string]: boolean | { accessible: boolean; embedded: { [key: string]: boolean } } | { [key: string]: boolean };
    };
  };
}

export interface menuItem {
  id: string;
  icon: any;
  title: any;
  href: string;
  openInNewTab?: boolean;
  role: [string?];
  excludeRole: [string?];
  settings: [string?];
  modules?: string[];
  conditional?: boolean;
  children?: childMenuItem[];
  hasBadge?: boolean;
  badgeTooltip?: string;
  eventType?: string;
  isBeta?: boolean;
  privilege?: keyof typeof PrivilegeTypes;
  hideable?: boolean;
  description?: string;
  showInTemplate?: EventStartTemplate[];
}

export interface GradientPickerValue {
  gradientColor: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  gradientSpread: number;
  gradientDirection: string;
}
export interface childMenuItem {
  id: string;
  title: string;
  subhref: string;
  icon?: any;
  eventType?: 'digital' | 'hybrid';
  conditional?: boolean;
  privilege?: keyof typeof PrivilegeTypes;
  hideable?: boolean;
  description?: string;
}

export interface menuSection {
  title?: string;
  divider?: boolean;
  items: menuItem[];
}

export interface MeetingAppointment {
  _id: string;
  meetingroom: Meetingroom;
  user: string;
  datetime_start: Date;
  datetime_end: Date;
}

export interface QaQuestion {
  id: string;
  _id: string;
  page: PageData;
  status: string;
  question: string;
  user: User;
  categories: QaCategory[];
  datetime_created: Date;
  datetime_accepted: Date | null;
  datetime_rejected: Date | null;
  datetime_deleted: Date | null;
  datetime_hidden: Date | null;
  datetime_shown: Date | null;
  likes: string[];
  answers: QaAnswerInterface[];
}
export interface SingleAccessRule {
  name?: string;
  errorMessage?: string;
  profileFields?: [
    {
      field: string;
      expectedValue: string;
    }
  ];
}

export interface AccessRuleComplex {
  altSignup?: string;
  _id?: string;
  event: string;
  hasTimeRestrictions?: boolean;
  datetime_active_from?: Date | null;
  datetime_active_to?: Date | null;
  datetime_created?: Date;
  datetime_deleted?: Date;
  ruleSet?: SingleAccessRule[];
  name?: string;
}
export interface Sidebar {
  mode:
    | 'chat'
    | 'media'
    | 'note'
    | 'qa'
    | 'participants'
    | 'admin'
    | 'queue'
    | 'orientation'
    | 'appointment'
    | 'meetingsettings'
    | 'files'
    | 'overlayvideoplay'
    | 'breakout'
    | 'settings'
    | 'poll'
    | 'eventoptions'
    | null;
}

export interface MuxPlaybackId {
  policy: String;
  id: String;
}

export interface MuxAsset {
  path?: string;
  muxId?: string;
  playback_ids?: MuxPlaybackId[];
}

export type MediaTypes = 'preuploadpresentation' | 'vystemvideo' | 'boothvideo' | 'pdf' | 'image' | 'meetingRecording' | 'streamRecording';
export interface media {
  _id: string;
  videoApiId: string;
  event: string;
  type: MediaTypes;
  path: string;
  name: string;
  number: string;
  author: string;
  authorposition: string;
  file: string;
  description: string;
  datetime_created: string;
  thumbnail?: string;
  playback_ids?: MuxPlaybackId[];
  muxId?: string;
  isProcessing?: boolean;
}

export interface Stage {
  number: number;
  name: string;
  slots: Slot[];
}

export interface Room {
  image: string;
  id: string;
  contactperson: string;
  contactposition: string;
  meetingtype: string;
  description: string;
  name: string;
  booth: Booth;
  status: any;
}

export interface Exhibitor {
  headerImages: string[];
  logo: string;
  name: string;
  offer: string;
  teaser: string;
  description: string;
  slogan: string;
  backgroundImage: string;
}

export interface PageData {
  logo?: string | null;
  linkSettings?: {
    openInNewTab: boolean;
    url: string;
  };
  _id: string;
  name: string;
  slug?: string;
  description?: string;
  event: any;
  team?: Organization;
  template?: {
    buttonText?: string;
    siteTemplate?: string;
    advancedFilters?: string[];
    excludeFilters?: string[];
    backgroundImage?: string;
    backgroundColor?: string;
    backgroundBlur?: number;
    overlayColor?: string;
    overlayOpacity?: number;
    fontFamily?: string;
    headerSentence?: string;
    showHeaderArrow?: boolean;
    headerArrowText?: string;
    showSearch?: boolean;
    showFilter?: boolean;
    htmlContent?: string | null;
    menuBar?: {
      backgroundColor?: string;
      textColor?: string;
      menuFade?: boolean;
      showDeviceSettings?: boolean;
      active?: boolean;
      showLogo?: boolean;
      showTitle?: boolean;
      alignLogo?: string;
      logoImage?: string;
      logoSize?: number;
      showEventInfo?: boolean;
      showNotifications?: boolean;
      title?: string;
      bubbleText?: string;
      fontFamily?: string;
      upperText?: string;
      view: 'normal' | 'minimalist';
      opacity: number;
      fontSize: number;
      bubbleFontSize: number;
      marginBetweenTitles?: number; // mbw
    };
    isCustomFooter?: boolean;
  };
  isLandingPage?: boolean;
  isHomePage?: boolean;
  isStatic?: boolean;
  isSignupPage?: boolean;
  isSignupSuccessPage?: boolean;
  visibility?: {
    visibleApp: boolean;
    visibleWeb: boolean;
  };
  stageSettings: {
    interactionOnly: boolean;
    streamUrl: string;
    streamType: 'Youtube' | 'Vimeo' | 'VideoSync' | 'url' | 'vystemvideo' | 'hlsurl' | 'Vystemstream' | 'vystemStudio' | 'iFrame' | 'videourl' | 'media';
    protected: boolean;
    placeHolderImage?: string;
    qa: {
      active: boolean;
      settings: {
        showQuestionsPublic: boolean;
        showOwnQuestionsStatus: boolean;
        myQuestionsAlternativeText: string;
      };
      defaultOpen: boolean;
      alternativePageId: string;
    };
    chat: Record<string, boolean>;
    poll: Record<string, boolean>;
    speakers: Record<string, boolean>;
    agenda: Record<string, boolean>;
    downloads: Record<string, boolean>;
    selfinfo: Record<string, boolean>;
    companyinfo: Record<string, boolean>;
    slido: any;
    like: Record<string, boolean>;
    autoplay?: boolean;
    livestream: string | null;
    //Deprecated
    iFrame: string | null;
    security: {
      threeQProjectKey: string;
      threeQProjectId: string;
    };
    vystemstream?: LiveStream;
    vystemStudio?: LiveStream;
    isLoopVideo?: boolean;
    videoSync?: {
      videoPlaybackStart: Date;
      eventTimezone: string;
      endAction: string;
      redirectPath: string;
      useRenderTask: boolean;
    };
    isPippable?: boolean;
    thumbnail?: string; //Quickfix for DHBW - currently no upload functionality
  };
  presentationSettings: {
    excludeMedia: boolean;
  };
  pageIndex?: number;
  showOnLivePage: boolean;
  // TODO: Add type here
  uicomponents: ALL_COMPONENTS_INTERSECTION_TYPE[];
  uicolumncomponents: ALL_COMPONENTS_INTERSECTION_TYPE[];
  isRestrictOnRegistrationFields: boolean;
  restrictedByRegistrationFields: RestrictedFieldValuePair[];
  translations: CustomTranslation[];
  customURL?: string;
  isRestrictedOnlyToExhibitors?: boolean;
  selectedTeamPages: { page: string; sortIndex: number }[];
}

export interface CustomTranslation {
  language: 'de' | 'en';
  value: string;
}

export interface RestrictedFieldValuePair {
  field: string;
  value: string;
}
export interface AttendeeFilterSetup {
  withLock: boolean;
  comparator: string;
  withGeneralFilters: boolean;
  lockStatus: 'locked' | 'unlocked';
  withSignupFormFilter: boolean;
  withProfileFieldFilters: boolean;
  signupForm: string;
  profileFieldFilters?: {
    field: string;
    value: string;
  }[];
  excludeUsersAwaitingFeedback: boolean;
}

export interface EventPollQuestion {
  _id: string;
  event?: string;
  datetime_created: Date;
  datetime_deleted: Date;
  content: string;
  isActive: boolean;
  totalVotes: number;
  answers: EventPollAnswer[];
  isShared: boolean;
  outputShowWholeAnswer?: boolean;
  isAnswered?: boolean;
  showOnPreviewMonitor?: boolean;
  meeting?: string;
  showPercentage?: string;
  allowMultiSelect?: boolean;
}

export interface EventPollAnswer {
  isAnswered: boolean;
  _id: string;
  event: string;
  datetime_created: Date;
  datetime_deleted: Date;
  votes: number;
  percentage: number;
  content: string;
  question: string;
  sortIndex?: number;
}
export interface NotificationInterface {
  _id: string;
  event: EventInterface;
  channels: string[];
  notification: notificationEntity;
  datetime_created: string;
  datetime_seen?: string;
  type: keyof NotificationTypes;
}
export interface QaCategory {
  id: string;
  _id: string;
  category: string;
  event: EventInterface;
  color: string;
}

export interface QaAnswerInterface {
  id: string;
  question: string;
  user: User;
  answer: string;
  datetime_created: string;
}
export interface QaControlOperation {
  id: string;
  operation: string;
  title: string;
  icon: IconDefinition;
  color: string;
  showHidden: boolean;
  questions: QaQuestion[];
}

export interface MeetingQueuePosition {
  _id: string;
  user: string;
  meetingroom: Meetingroom;
  forwardedFromMeetingroom: string | null;
  datetime_created: Date;
  datetime_ack: Date;
  datetime_leave: Date;
  datetime_response: Date;
  jointoken: string;
  response: 'accept' | 'reject';
  datetime_deleted: Date;
  datetime_hidden: Date;
}

export interface QueueInvite {
  meetingroomId: string;
  contactPerson: string;
  contactPersonImage: string;
  boothHeroImage: string | null;
  queueId: string;
  datetime_sent: Date;
  validForSeconds: number;
}

export interface MatchingInvite {
  meetingId: string;
  contactPerson: string;
  contactPersonImage: string | null;
  boothHeaderImage: string | null;
  boothName: string;
  datetime_sent: Date;
  validForSeconds: number;
  message?: string | null;
}

export interface QueueEntry {
  _id: string;
  user: {
    _id: string;
    name: string;
    avatar?: string;
    currentMeeting?: string;
    realtimeStatus: 'online' | 'offline';
    registrations: EventRegistration[];
  };
  datetime_created: Date;
  datetime_hidden: Date | null;
  forwardedFromMeetingroom: {
    _id: string;
    contactperson: string;
    image?: string;
  };
}

export interface QueueState {
  entries: QueueEntry[];
  isInviting: boolean;
  inviteStatus: QueueInviteStatus | null;
  failureErrorKey: string | null;
  //Should be deprecated and moved to queue item
  invitingUserObject?: User | null;
  invitingQueueItem?: QueueEntry | null;
  isLoading: boolean;
  canInvite: boolean;
  soundOnQueueJoin: boolean;
}

export interface Queuer {
  _id: string;
  user: {
    _id: string;
    name: string;
    avatar?: string;
    currentMeeting?: string;
    realtimeStatus: 'online' | 'offline';
    registrations: EventRegistration[];
  };
  datetime_created: Date;
  datetime_hidden: Date | null;
  forwardedFromMeetingroom: {
    _id: string;
    contactperson: string;
    image?: string;
  };
}
export type NotificationTypes = {
  QUEUE_INVITE: notificationEntity;
  APPOINTMENT_REMINDER: notificationEntity;
  PRESENTATION_PRESENTER_REMINDER: notificationEntity;
  PRESENTATION_FAVOURITE_REMINDER: notificationEntity;
  CONTACT_SHARE_SUMMARY: notificationEntity;
  ORIENTATION_INVITE: notificationEntity;
  EVENT_BROADCAST: notificationEntity;
};

export interface notificationEntity {
  pushTitle?: string; // the title used in the push notification recommended 40 character length
  pushBody?: string; // recommended 80 character length
  file?: string;
  meetingroomId?: string;
  presentationName?: string;
  contactperson?: string;
  broadcastMessage?: string;
}
export interface EventRegistrationField {
  label: string;
  helperText: string;
  isHidden: boolean;
  isSuffix: boolean;
  textValueOptions: string[];
  visibleToExhibitors: boolean;
  _id: string;
}
export interface EventTemplate {
  _id: string;
  name: string;
  description: string;
  eventId: string;
  previewImage: string;
  datetime_created: Date;
  datetime_deleted: Date;
}

export interface FavoriteAgendaEntry {
  entry: AgendaEntry;
  user: string;
  component: AgendaComponentInterface;
}
export interface OrientationState {
  timeLeft: number;
  canInvite: boolean;
  isLoading: boolean;
  isInviting: boolean;
  potentialMatches: MatchUser[] | null;
  invitingUserObject: {
    name: string | null;
    _id: string | null;
    response: 'accept' | 'reject' | 'error' | null;
  };
  filter: RegistrationFieldFilter[];
}

export interface MatchUser {
  email: string;
  _id: string;
  realtimeStatus: 'online' | 'offline';
  name: string;
  currentMeeting: string;
  avatar?: string;
  registrationFields: [
    {
      field: {
        label: string;
        visibleToExhibitors?: boolean;
        fieldType: string;
      };
      value: string;
    }
  ];
}

export type AttendeeMessagePopulated = Omit<AttendeeMessage, 'recepient' | ' sender'> & {
  recepient: User;
  sender: User;
};

export interface AttendeeMessage {
  _id: string;
  sender: string;
  recepient: string;
  message: string;
  messageType: MessageType;
  event: string;
  datetime_created: Date;
  datetime_seen: Date;
  datetime_ended: Date;
  isPending?: boolean;
  isError?: boolean;
  files?: MessagingFile[];
  fileIds?: string[]; // This is only used for sending files
  conversationKey?: string; // Added in common slice
}

export interface EmailTemplateSettings {
  currentTemplate?: EmailTemplate;
  t?: TFunction;
}

export interface UserBan {
  _id: string;
  type: 'CHAT' | 'JOIN' | 'NAME_CHANGE' | 'IP_REGISTRATION_BAN' | 'MESSAGING';
  event: EventInterface;
  user: User;
  datetime_deleted?: Date;
  datetime_created?: Date;
}

export interface ReportUser {
  event: string;
  user: string;
  datetime_created?: Date;
  reason: string;
  reporter: string;
}
export interface BoothOrgUser {
  name: string;
  email: string;
  role: RoleName;
  _id: string;
  organization: string;
}

export interface BoothAccessUserType {
  _id: string;
  name: string;
  email: string;
  role: RoleName;
  avatar: string | null;
}

export interface PrivilegeRequestPopulated extends Omit<PrivilegeRequestInterface, 'event'> {
  event: EventInterface;
}

export interface PrivilegeRequestInterface {
  _id: string;
  event: string;
  user: User;
  privilege: string;
  datetime_processed: string;
}
export interface TrackingEvent {
  _id: string;
  userid: string;
  eventid: string;
  datetime_created: Date;
  datetime_leave?: Date;
  args: any;
  eventtype: string;
}

export interface TrackingEventPopulated extends Omit<TrackingEvent, 'meetingid'> {
  meetingid?: Meetingroom;
}

export type MessageType = 'MESSAGE' | 'CALL' | 'VCARD';

export type MessagingAttendee = Pick<
  User,
  '_id' | 'avatar' | 'name' | 'datetimeLastRealtimeConnect' | 'datetimeLastRealtimeDisconnect' | 'currentMeeting' | 'registrationFields' | 'profileStatus'
> & {
  backgroundColor?: string;
};

export interface MessagingConversation {
  conversationKey?: string;
  userA: MessagingAttendee;
  userB: MessagingAttendee;
  messages: AttendeeMessage[];
  bgColor?: string;
}

export interface CanvasElement extends HTMLCanvasElement {
  captureStream(frameRate?: number): MediaStream;
}

export interface Output {
  _id: string;
  event: string;
  name: string;
  outputId: string;
  datetime_created: Date;
  datetime_deleted: Date | null;
  isPreviewInverted: boolean;
  activeContent: string[];
  countdownValue: number;
  countdownAmount: number;
  datetime_countdown_started?: Date;
  datetime_countdown_paused?: Date;
  fontSize: number;
  margin: number;
  chartFontSizeX?: number;
  chartFontSizeY?: number;
  invertPreview: boolean;
  axisColor?: string;
  barColor?: string;
  hideQAUsername?: boolean;
  enableAutoplay?: boolean;
  useOrgDefaultBackground?: boolean;
  backgroundImage?: string;
  textColor?: string;
  backgroundColor?: string;
}

export interface OutputPopulated extends Omit<Output, 'event'> {
  event: EventInterface;
}

export type MeetingDisplayStatus = 'open' | 'queue' | 'offline' | 'live';

export interface MeetingStatusUpdate {
  id: string; //Meetingid
  s: {
    //Status
    A: 0 | 1; //Alive
    L: 0 | 1; //Live
    C: 0 | 1; //Can Join
  };
}

export interface PageTemplate {
  value: string;
  displayName: { 'en-EN': string; 'de-DE': string; 'fr-FR'?: string };
  icon: string;
  modules?: string[];
}
export interface MessageRegistrations {
  user: {
    email: string;
    name: string;
    role: string;
    _id: string;
  };
  phone: string;
  providertoken: string;
  provider: string;
  registrationId: string;
}

export interface MessageWithStatus {
  _id: string;
  type: 'incoming' | 'outgoing';
  channel: string;
  from: string;
  to: string;
  message_uuid: string;
  message_type: string;
  text?: string;
  image?: {
    url?: string;
  };
  datetime_created: Date;
  messageStatus: {
    status: string;
    datetime_created: Date;
  }[];
}

export interface WhatsappUserSummary {
  user: {
    email: string;
    name: string;
    role: string;
    _id: string;
  };
  phone: string;
  providertoken: string;
  provider: string;
  registrationId: string;
  messages: MessageWithStatus[];
  messageWindow?: { number: string; datetime_start: Date; datetime_end: Date } | null;
}

export interface RegistrationFieldFilter {
  field: string;
  values: string[];
}

export interface UserMatch {
  participantA: {
    registration: EventRegistration;
    rating?: number;
    hasHighlightedMatch: boolean;
  };
  participantB: {
    registration: EventRegistration;
    rating?: number;
    hasHighlightedMatch: boolean;
  };
  isDiscovered: boolean;
  event: string;
  room: string;
  datetime_created: Date;
  datetime_last_match: Date;
  _id: string;
  score: number;
}

export type EmailHistoryStatus = 'open' | 'click' | 'bounce' | 'spam' | 'blocked' | 'unsub' | 'sent';

export interface Email {
  to: string;
  cc: string;
  replyTo: string;
  datetime_created: Date;
  resendEmailId: string;
  datetime_sent: Date;
  datetime_canceled: Date;
  datetime_failed: Date;
  datetime_task_created: Date;
  failureReason: string;
  failureCount: number;
  datetime_scheduled: Date;
  from: string;
  eventId?: string;
  userId?: string;
  attachments: string[];
  fromName: string;
  language: string;
  mailService: string;
  templateId: string;
  templateName: string;
  dynamic_template_data: string;
  renderData: any;
  appendRegistrationToRenderData: boolean;
  attendeeInfo?: string;
  customTemplateId?: string;
  status_history: {
    status: EmailHistoryStatus;
    category: string;
    datetime: Date;
  }[];
  lastStatus?: EmailHistoryStatus;
}

export interface ManagedToast {
  id: string;
  onStack: boolean;
}

export type PresentationTicketRegistrationPopulated = Omit<PresentationTicket, 'registration'> & {
  registration: EventRegistration;
  trackingevent: TrackingEvent;
};

export interface PresentationTicket {
  _id: string;
  registration: string;
  slot: string;
}

export interface TicketDiscountCode {
  _id: string;
  code: string;
  limit?: number;
  type: 'limited' | 'unlimited';
  percentage: number;
  ticketTypes?: string[];
}

export type SupportedTicketPaymentTypes = 'free' | 'chargeable' | 'donation';
export type SupportedTicketPaymentCurrencies = 'USD' | 'EUR' | 'CHF' | 'GBP' | 'AUD';

export interface TicketType {
  price: number;
  name: string;
  paymentType: SupportedTicketPaymentTypes;
  description?: string;
  _id?: string;
  currency: SupportedTicketPaymentCurrencies;
  minPerOrder?: number;
  maxPerOrder?: number;
  amountAvailable?: number | null;
  allowTimeRestriction?: boolean;
  datetime_available?: Date;
  datetime_expiry?: Date;
  sortIndex: number;
  registrationEmailTemplate?: string | null;
  datetime_hidden?: boolean;
  isAvailable?: boolean;
  teaser?: string;
}
export interface DetailedTicketType extends TicketType {
  amountBooked?: number;
}

export interface BillingDataInterface {
  firstname: string;
  lastname: string;
  street: string;
  postcode: string;
  city: string;
  country: string;
  email: string;
  vat: string;
}

export interface BasketItem {
  type: TicketType;
  singlePrice: number;
  amount: number;
}

export interface TicketOrder {
  _id?: string;
  discountCodes: string[];
  orderNumber?: string;
  rawPaymentData?: { method?: string };
  payment?: {
    id: string;
    status: string;
    amount?: { value: string; currency: SupportedTicketPaymentCurrencies };
    redirectUrl: string;
  };
  basket: BasketItem[];
  billingEmail: string;
  event: EventInterface;
  registrations: EventRegistration[];
  invoice?: string;
  invoiceNumber?: number;
  datetime_cancelled: Date | null;
  datetime_deleted: Date | null;
  datetime_created: Date | null;
  datetime_chargeback_created: Date | null;
  datetime_invoice_created: Date | null;
  datetime_invoice_sent: Date | null;
  datetime_reservation_start: Date | null;
  datetime_reservation_end: Date | null;
  isReserved?: boolean;
  basketReservationTime?: number;
  costs: {
    subTotal: number;
    tax: number;
    gross: number;
    discount: number;
    taxRequired?: boolean;
  };
  billingData: {
    company?: string;
    firstname?: string;
    lastname?: string;
    street?: string;
    postcode?: string;
    city?: string;
    country?: string;
    email: string;
    vat?: string;
    comment?: string;
  };
  redirectUrl?: string;
  isGroupOrder?: boolean;
  mainTicketOrderNumber?: string;
}

export interface ControllingEntry {
  totalVideoConferenceMinutes: number;
  totalStreamMinutes: number;
  eventName: string;
  eventSlug: string;
}

export interface BillingInfos {
  date: string;
  totalVideoMinutes?: number;
  totalStreamingMinutes?: number;
}

export interface SelectedFile extends File {
  progress?: number;
  fileId?: string;
  uploadState?: 'SUCCESS' | 'FAILURE';
  src?: string;
}

export interface OrgMembership {
  _id: string;
  assignedMeetingroom?: string;
  organization: Organization;
  role: RoleName;
  user: User;
}

export interface DefaultDeviceStates {
  micOn: boolean;
  camOn: boolean;
}
export interface MeetingActivities {
  messages: MeetingChatMessage[];
  trackingEvents: TrackingEvent[];
}

export interface AttendeeDetail {
  firstTimeSeen: string;
  lastTimeSeen: string;
  user: User;
  duration: number;
  reason: string;
}

export interface MeetingDetails {
  details: AttendeeDetail[];
  total: number;
  totalMinutes: number;
}
export interface ApiKey {
  _id: string;
  name: string;
  code?: string;
}

export interface SpeakerModalInterface {
  speaker: SpeakerEntry;
  textColor?: string;
  showPosition?: boolean;
}

export interface Promotion {
  fileIds: {
    en: string;
    de: string;
    fr: string;
  };
  urls: {
    en: string;
    de: string;
    fr: string;
  };
  version: number;
}

export interface PlatformBudget {
  datetime_created: Date;
  description?: string;
  minuteValue: number;
  type: 'video' | 'stream';
}

export enum PremiumServiceType {
  CustomAppstoreApp = 'customAppstoreApp',
  ProAnalytics = 'proAnalytics',
  CustomDomain = 'customDomain',
  Seo = 'seo',
}

export enum OrderStatus {
  Unprocessed = 'unprocessed',
  PendingPayment = 'pending-payment',
  Ongoing = 'ongoing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Declined = 'declined',
}

export interface PremiumServiceOrder {
  _id: string;
  user: User;
  organization: Organization;
  event: string;
  service: PremiumServiceType;
  additionalOptions?: { name: string; price: number }[];
  additionalData?: { name: string; value: string }[];
  basePrice: number;
  total: number;
  status: OrderStatus;
  featureIsActive?: boolean;
  datetime_created: string;
  datetime_deleted?: string;
}

export interface DateTimeChangeProps {
  key: 'datetime_start' | 'datetime_end';
  value: Date;
}

export type templateModalAction = 'edit' | 'add';

export interface ProgressItem {
  itemName: keyof Booth;
  translation: string;
  tab: string;
}
export interface BoothProgressInterface {
  requiredItems: ProgressItem[];
  completedItems: ProgressItem[];
  progress: number;
}
