import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMobileApp } from '../../hooks/useMobileApp';
import { useSelector } from '../../reduxToolkit/typedSelector';
import { createDisplayLink } from '../../services/helper';
import { useDispatch } from 'react-redux';
import { fetchAndSetEvent, setIntercom } from '../../reduxToolkit/slices/commonSlice';

const disabledPaths = ['/output/', '/studiopreview'];

export default function SupportTool() {
  const event = useSelector((state) => state.common.event ?? state.admin.event);
  const user = useSelector((state) => state.common.user);
  const intercomId = useSelector((state) => state.common.intercomId);
  const organization = useSelector((state) => state.common.user?.organization ?? state.common.event?.organization);

  const router = useRouter();
  const { isAppView } = useMobileApp();
  const dispatch = useDispatch();
  /**
   * We show the default Intercom on the account pages,
   * on other pages custom settings apply
   */

  const isAccount = router.asPath?.includes('/account');
  const isEvent = !isAccount && router.asPath?.includes('/event/');
  const defaultIntercomId = process.env.defaultIntercomId ?? 'cbam6m39';
  const intercomPathDisabled = disabledPaths.some((path) => router.pathname.includes(path));

  const [fetchComplete, setFetchComplete] = useState(false);
  const [hasConsent, setHasConsent] = useState(false);
  const [hideIntercom, setHideIntercom] = useState(true);

  function checkCookieSettings() {
    if (window && (window as any).Cookiebot) {
      const bot = (window as any).Cookiebot;
      setHasConsent(bot?.consent?.necessary);
    }
  }

  function handleIntercomSettings() {
    try {
      (window as any).intercomSettings = {
        app_id: isAccount ? defaultIntercomId : intercomId ?? defaultIntercomId,
        name: user?.name,
        email: user?.email,
        orgid: user?.organization?._id,
        orgname: user?.organization?.name,
        user_id: user?._id,
        eventname: event?.name,
        eventlink: event?.slug && `${process.env.baseURL}/event/${event?.slug}`,
        eventid: event?._id,
        hide_default_launcher: true,
      };
      if (user?.avatar) {
        (window as any).intercomSettings.avatar = {
          type: 'avatar',
          image_url: createDisplayLink(typeof user?.avatar == 'string' ? user?.avatar : user?.avatar?._id),
        };
      }
    } catch (err) {
      console.error('Error: ', err);
    }

    if ((window as any)?.Intercom) {
      try {
        (window as any).Intercom('update', {
          hide_default_launcher: hideIntercom,
        });
      } catch (err) {
        console.error('Error: ', err);
      }
    }

    try {
      (window as any)?.LogRocket &&
        (window as any)?.LogRocket.identify(user?._id, {
          name: user?.name,
          email: user?.email,
          orgid: user?.organization?._id,
          orgname: user?.organization?.name,
          eventname: event?.name,
          eventid: event?._id,
        });
    } catch (err) {
      console.error('Error: ', err);
    }
  }

  useEffect(() => {
    setFetchComplete(false);

    const { slug } = router.query;
    if (slug) dispatch(fetchAndSetEvent({ slug: slug.toString() }));

    setFetchComplete(true);
  }, []);

  useEffect(() => {
    if (!window) return;
    checkCookieSettings();
    window.addEventListener('CookiebotOnLoad', checkCookieSettings);

    return function cleanup() {
      window?.removeEventListener('CookiebotOnLoad', checkCookieSettings);
    };
  }, [event]);

  useEffect(() => {
    if (organization?.integrations?.intercom.intercomId) dispatch(setIntercom(organization?.integrations?.intercom.intercomId));
  }, [organization?.integrations?.intercom]);

  useEffect(() => {
    if ((!hasConsent && !isAppView()) || !fetchComplete) return;

    handleIntercomSettings();
  }, [hasConsent, intercomId, hideIntercom, user]);

  useEffect(() => {
    const _hideIntercom =
      isAppView() ||
      (isEvent && !event) || // if inside an event, we hide intercom as long as we wait for event redux state to be loaded
      (organization?.integrations?.intercom?.showIntercomSupport === false && !isAccount) ||
      intercomPathDisabled;

    setHideIntercom(_hideIntercom);
  }, [event, isEvent, isAccount, intercomPathDisabled, organization?.integrations?.intercom]);
  return null;
}
