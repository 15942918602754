import { LinkProps } from 'next/dist/client/link';
import Link from 'next/link';
import React, { CSSProperties } from 'react';

interface LinkWithAProps extends React.PropsWithChildren<LinkProps> {
  newTab?: boolean;
  className?: string;
  style?: CSSProperties;
  id?: string;
}

/**
 * this components wraps the NextJS Link with a native <a> tag
 * the benefit of using it is to directly support open in new tab and URL preview in browsers. see: https://imgur.com/a/pT33N62
 */
export const LinkWithA = (props: LinkWithAProps) => (
  <Link {...props}>
    <a
      id={props.id}
      className={props.className}
      style={props.style}
      target={props.newTab ? '_blank' : '_self'}
      href={props.as ? props.as.toString() : props.href.toString()}>
      {props.children}
    </a>
  </Link>
);
