import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { VyLoader } from '../components/common/VyLoader';
import { useLanguage } from '../hooks/useLanguage';

export const AutoLanguageWrapper: React.FC = (props) => {
  const { checkNavigatorLanguage, isCheckingLanguage } = useLanguage();

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;
    checkNavigatorLanguage();
  }, [router.isReady]);

  if (isCheckingLanguage) return <VyLoader solidBackground />;

  return <>{props.children}</>;
};
