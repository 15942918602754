import '@fortawesome/fontawesome-svg-core/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import 'core-js/stable';
// import { useStore } from "../redux/store";
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import NProgress from 'nprogress'; // nprogress module
import { useEffect, useState } from 'react';
import 'react-csv-importer-dwerth/dist/index.css'; // style for csv importer
import 'react-medium-image-zoom/dist/styles.css';
import 'react-phone-number-input/style.css';
import { Provider } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import 'regenerator-runtime/runtime';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import UnsupportedBrowser from '../components/common/UnsupportedBrowser';
import VyFooterHorizontal from '../components/common/VyFooterHorizontal';
import { CookieServices } from '../components/CookieServices';
import Global from '../components/Global';
import theme from '../components/MaterialTheme';
import LoaderProvider from '../HOC/LoaderProvider';
import { useMobileApp } from '../hooks/useMobileApp';
import store from '../reduxToolkit/store';
import '../static/formbuilder.css';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { Toaster } from 'react-hot-toast';
import { setShowBudgetWarning, setShowPremiumFunctions } from '../reduxToolkit/slices/adminSlice';
import '../static/nprogress.css'; // styles of nprogress
import '../static/overwrites.css';
import '../static/tailwind.css';
import { isSupportedBrowser } from '../utils/functions';

// import { pick } from '../utils/languageParser';

// Disable console.log on everything but dev
// if (process.env.NODE_ENV !== 'development') console.log = console.warn = console.error = () => {};

function getFaviconHref() {
  if (process?.env?.baseURL?.includes('localhost')) {
    return '/favicon/local.ico';
  } else if (process?.env?.baseURL?.includes('develop')) {
    return '/favicon/develop.ico';
  } else if (process?.env?.baseURL?.includes('staging')) {
    return '/favicon/staging.ico';
  } else {
    return '/favicon/favicon.ico';
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const [usingApp, setUsingApp] = useState<boolean | undefined>();
  const { isAppView } = useMobileApp();

  useEffect(() => {
    initLogsConfig();
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (typeof window !== 'undefined') {
      const usingApp = isAppView();
      setUsingApp(usingApp);

      if (!usingApp) {
        NProgress.configure({ showSpinner: true });
        Router.events.on('routeChangeStart', (e) => NProgress.start());
        Router.events.on('routeChangeComplete', () => NProgress.done());
        Router.events.on('routeChangeError', () => NProgress.done());
      }

      return function cleanup() {
        if (!usingApp) {
          Router.events.off('routeChangeStart', () => NProgress.start());
          Router.events.off('routeChangeComplete', () => NProgress.done());
          Router.events.off('routeChangeError', () => NProgress.done());
        }
      };
    }
  }, []);

  if (!isSupportedBrowser()) return <UnsupportedBrowser />;

  async function initLogsConfig() {
    try {
      const res = await axios.post('/config/get', { path: 'frontend' });
      const { enableLogs, promotion, showBudgetWarning, showPremiumFunctions } = res.data.data;
      store.dispatch(setShowBudgetWarning(showBudgetWarning));
      store.dispatch(setShowPremiumFunctions(showPremiumFunctions));
      if (!!!enableLogs || enableLogs === false) console.log = console.warn = console.error = () => {};
    } catch (e) {}
  }

  if (typeof window !== 'undefined') {
    posthog.init('phc_9ZBg4UC1LTliGW8hzJVf7bvazVOJBDr0G4cILXfrkmV', {
      api_host: 'https://eu.posthog.com',
      disable_session_recording: true,
      loaded: (posthog) => {
        posthog.onFeatureFlags((flags) => {
          if (posthog.isFeatureEnabled('record-na')) posthog.startSessionRecording();
        });
      },
    });
  }

  return (
    <Provider store={store}>
      <Head>
        <noscript>Your browser does not support JavaScript, or you have intentionally disabled it.</noscript>
      </Head>
      <DefaultSeo
        additionalLinkTags={[
          {
            rel: 'shortcut icon',
            keyOverride: 'shortcut icon',
            href: getFaviconHref(),
          },
          {
            rel: 'icon',
            keyOverride: 'icon',
            href: getFaviconHref(),
          },
          {
            rel: 'apple-touch-icon',
            keyOverride: 'apple-touch-icon',
            href: getFaviconHref(),
          },
          {
            rel: 'apple-touch-icon-precomposed',
            keyOverride: 'apple-touch-icon-precomposed',
            href: getFaviconHref(),
          },
        ]}
        defaultTitle="vystem.io"
        openGraph={{
          type: 'website',
          url: 'https://app.vystem.io/',
          site_name: 'vystem.io',
        }}
      />
      <>
        <Toaster />

        <CookieServices />
        {/* <ToastsNotifications ref={ToastsNotificationsRef} /> */}
        {/* 76 is the height of the footer */}
        <div style={{ minHeight: 'calc(100vh - 76px)' }}>
          <LoaderProvider>
            <PostHogProvider client={posthog}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <Global />
                    <Component {...pageProps} />
                  </ThemeProvider>
                </StyledEngineProvider>
              </LocalizationProvider>
            </PostHogProvider>
          </LoaderProvider>
        </div>
        {!usingApp && <VyFooterHorizontal />}
      </>
      {/* This could be moved to CookieServices.tsx, but I have not found the code where I can activate cookies for localhost to test i */}
      <style global jsx>
        {`
          #CybotCookiebotDialog {
            display: ${usingApp ? 'none !important' : 'inherit'};
          }
        `}
      </style>
    </Provider>
  );
}

/**
 * Multilanguage support
 * Ref: https://github.com/vercel/next.js/discussions/22405
 */
/* MyApp.getInitialProps = async (appContext: AppContext) => {
  const {
    ctx: { req, res },
    ctx,
  } = appContext;

  if (process.env.env !== 'dev') {
    console.log('--- New Request--- ');

    const currentLocale = (appContext.ctx as any)?.locale;
    console.log('Next locale cookie: ', (req as any)?.cookies?.NEXT_LOCALE);
    const nextLocale = (req as any)?.cookies?.NEXT_LOCALE;
    let userLanguage: null | 'de' | 'en';
    if (nextLocale) {
      userLanguage = nextLocale;
    } else {
      userLanguage = pick(['de', 'en'], ctx?.req?.headers['accept-language'], { loose: true }) || 'de';
    }

    console.log('Current locale ', currentLocale);
    console.log('User Language ', userLanguage);
    console.log('URL ', req?.url);
    //console.log(appContext);
    if (currentLocale && userLanguage && userLanguage !== currentLocale) {
      if (req && res) {
        const redirectUrl = `${userLanguage !== 'de' ? `/${userLanguage}` : ''}${req.url || ''}`;
        if (redirectUrl) {
          console.log('Redirect to ', redirectUrl);
          // Router.push(`/${userLanguage}${req.url}`)
          // console.log("Setting locale cookie to ", userLanguage);
          // res.setHeader('set-cookie', `NEXT_LOCALE=${userLanguage};path=/`)
          res.writeHead(302, { Location: redirectUrl });
          res.end();
        }
      }
    }
  }
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};*/

export default appWithTranslation(MyApp);
