import axios, { AxiosInstance } from 'axios';

export class customAxios {
  private static axiosInstance: AxiosInstance;

  static initialize(): void {
    this.axiosInstance = axios.create();
    this.axiosInstance.interceptors.response.use(
      (res) => res,
      (err) => Promise.reject(err)
    );
  }

  static async post(endpoint: string, paramaters: any = {}, config: any = {}, displayErrorToast = true): Promise<any> {
    if (displayErrorToast) return axios.post(endpoint, paramaters, config);
    return this.axiosInstance.post(endpoint, paramaters, config);
  }

  static async get(endpoint: string, paramaters: any = {}, displayErrorToast = true): Promise<any> {
    if (displayErrorToast) return axios.get(endpoint, paramaters);
    return this.axiosInstance.get(endpoint, paramaters);
  }

  static async delete(endpoint: string, paramaters: any = {}, displayErrorToast = true): Promise<any> {
    if (displayErrorToast) return axios.delete(endpoint, paramaters);
    return this.axiosInstance.delete(endpoint, paramaters);
  }
}
