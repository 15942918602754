import { Tooltip } from '@mui/material';
import React from 'react';

/**
 * this component is build to avoid .toString() conversions in the DOM
 * e.g. <Tooltip title={t('tooltip-registration-accepted').toString()}>
 */

export type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface VyTooltipProps {
  title?: React.ReactNode;
  children: React.ReactElement<any, any>;
  style?: React.CSSProperties;
  placement?: Placement;
  onClick?: Function;
}

const VyTooltip: React.FC<VyTooltipProps> = (props) => {
  const { title, children, style = {}, placement, onClick } = props;

  return title ? (
    <Tooltip placement={placement} title={title} style={style} onClick={() => onClick?.()}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
};

export default VyTooltip;
