import { VerifiedUser } from '@mui/icons-material';
import { Avatar, Badge, Menu, MenuItem } from '@mui/material';
import { isAfter } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { CSSProperties, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useMessaging from '../../../../hooks/useMessaging';
import { MeetingChatMessage, MessagingAttendee } from '../../../../interfaces/interfaces';
import { setOpenAttendeeMessaging, setProfileInfoUser, setShowProfileModal, setShowProfileStatusModal } from '../../../../reduxToolkit/slices/commonSlice';
import { setUserProfileModal } from '../../../../reduxToolkit/slices/videoSlice';
import { useSelector } from '../../../../reduxToolkit/typedSelector';
import { createDisplayLink } from '../../../../services/helper';
import { ConditionalWrapper } from '../../../common/ConditionalWrapper';
import VyTooltip from '../../../common/VyTooltip';
import { getContrastColor } from '../../../../utils/functions';

interface AttendeeAvatarInterface {
  attendee: MessagingAttendee;
  size?: 'small' | 'medium' | 'large';
  showStatus?: boolean;
  clickable?: boolean;
  isOrganizer?: boolean;
  isProfileModalOnly?: boolean;
  showStatusEmoji?: boolean;
  reportMessage?: MeetingChatMessage;
  onColorInitialized?: (color: string) => void;
}

const AttendeeAvatar: React.FC<AttendeeAvatarInterface> = ({
  attendee,
  size = 'medium',
  showStatus = false,
  clickable = true,
  isOrganizer = false,
  showStatusEmoji = false,
  reportMessage,
  isProfileModalOnly = false,
  onColorInitialized,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const event = useSelector(({ common }) => common.event);
  const user = useSelector(({ common }) => common.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openMessagingConversationFromAttendee } = useMessaging();

  const isConnected =
    !!attendee.datetimeLastRealtimeConnect &&
    (!attendee.datetimeLastRealtimeDisconnect || isAfter(new Date(attendee.datetimeLastRealtimeConnect), new Date(attendee.datetimeLastRealtimeDisconnect)));

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!clickable) return;
    if (!isProfileModalOnly && user?._id !== attendee._id && (event?.settings?.allowAttendeeMessaging || reportMessage)) {
      setAnchorEl(e.currentTarget);
    } else {
      checkProfile();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkProfile = () => {
    if (user?._id === attendee._id) dispatch(setShowProfileModal(attendee._id));
    else dispatch(setProfileInfoUser(attendee._id));
    handleClose();
  };

  const openConversation = () => {
    dispatch(setOpenAttendeeMessaging(true));
    openMessagingConversationFromAttendee(attendee);
    handleClose();
  };

  const openStatusModal = () => {
    dispatch(setShowProfileStatusModal(true));
    handleClose();
  };

  const openReport = () => {
    dispatch(setUserProfileModal(reportMessage));
    handleClose();
  };

  const AvatarClassNames = [size === 'large' ? 'h-14 w-14' : size === 'medium' ? 'h-10 w-10' : 'h-6 w-6'].join(' ');

  const onlineStyle: CSSProperties = { outline: '2px solid #23CE6B', outlineOffset: '1px' };

  const busyStyle: CSSProperties = { outline: '2px solid #E94F37', outlineOffset: '1px' };

  const avatarStyle: CSSProperties = isConnected && showStatus ? (!!attendee.currentMeeting ? { ...busyStyle } : { ...onlineStyle }) : {};

  const avatar = attendee.avatar?._id ?? attendee.avatar;

  if (attendee && user && attendee._id === user?._id && user?.email.includes('@event.vystem.io')) {
    return null;
  }

  return (
    <>
      <div className="cursor-pointer" onClick={handleClick}>
        <ConditionalWrapper
          condition={isOrganizer}
          wrapper={(children) => (
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              overlap="circular"
              badgeContent={
                <VyTooltip title={t('event:messaging.verified-organizer')}>
                  <VerifiedUser color="primary" style={{ fontSize: '20px' }} />
                </VyTooltip>
              }>
              {children}
            </Badge>
          )}>
          <div className="relative">
            <div className="absolute top-0 left-0 w-full h-full rounded-full animate-pulse" style={avatarStyle} />
            <VyTooltip title={t('event:live.profile')}>
              <Avatar
                className={AvatarClassNames}
                src={createDisplayLink(avatar, false)}
                style={{
                  backgroundColor: attendee.backgroundColor ?? getContrastColor(event?.template.mainColor),
                }}>
                {attendee?.name?.substr(0, 1)}
              </Avatar>
            </VyTooltip>
          </div>
        </ConditionalWrapper>
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={checkProfile}>{t('event:avatar-see-profile')}</MenuItem>
        {event?.settings.allowAttendeeMessaging && user?._id !== attendee._id && (
          <MenuItem onClick={openConversation}>{t('event:avatar-message-attendee', { name: attendee.name })}</MenuItem>
        )}
        {/* commented out until used again */}
        {/* {user?._id === attendee._id && <MenuItem onClick={openStatusModal}>{t('event:change-status', { username: attendee.name })}</MenuItem>} */}
        {reportMessage && user?._id !== attendee._id && <MenuItem onClick={openReport}>{t('event:report-message-tooltip')}</MenuItem>}
      </Menu>
    </>
  );
};
export default AttendeeAvatar;
