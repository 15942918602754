import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { usePermission } from '../../hooks/usePermission';
import { useSelector } from '../../reduxToolkit/typedSelector';
import ResetData from './ResetData';
import { LanguageSelectDropdown } from './LanguageSelect/LanguageSelectDropdown';

const DefaultFooter: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const event = useSelector((state) => state.common.event ?? state.admin.event);
  const user = useSelector((state) => state.common.user);
  const pagedata = useSelector((state) => state.common.pagedata);
  const { isEventHost } = usePermission();

  const useCustomUrls = (router.asPath?.includes('/event') || router.asPath?.includes('/t/')) && !router.asPath?.includes('/account');

  const showLanguageSwitch =
    (event?.languages && event?.languages?.length > 1) ||
    router.pathname.includes('login') ||
    router.pathname.includes('new_password') ||
    (router.pathname.includes('signup') && !event);

  const imprintUrl =
    useCustomUrls && pagedata?.team?.legal?.imprintUrl
      ? pagedata?.team?.legal?.imprintUrl
      : event?.legal?.imprintUrl
      ? event?.legal?.imprintUrl
      : 'https://vystem.io/impressum?utm_source=vystem.io&utm_medium=frontend&utm_content=footer';

  const termsUrl =
    useCustomUrls && event?.legal?.termsUrl ? event?.legal?.termsUrl : 'https://vystem.io/agb?utm_source=vystem.io&utm_medium=frontend&utm_content=footer';

  const privacyUrl =
    useCustomUrls && pagedata?.team?.legal?.dataPrivacyUrl
      ? pagedata?.team?.legal?.dataPrivacyUrl
      : event?.legal?.dataPrivacyUrl
      ? event?.legal?.dataPrivacyUrl
      : 'https://vystem.io/datenschutz?utm_source=vystem.io&utm_medium=frontend&utm_content=footer';

  const aStyle = 'text-[#bbb] no-underline hover:text-[#666] cursor-pointer';

  return (
    <>
      <div className={`w-full p-2 ${isEventHost(user, event) ? 'pl-[5rem]' : 'pl-2'} justify-center flex bg-slate-600`}>
        <ul className={`max-w-[1333px] w-full flex list-none flex-col sm:flex-row sm:items-center`}>
          {event?.legal?.hideImprint !== true && (
            <li className="mr-4">
              <a target="_blank" href={imprintUrl} className={aStyle}>
                {t('common:imprint')}
              </a>
            </li>
          )}
          {event?.legal?.hideTerms !== true && (
            <li className="mr-4">
              <a target="_blank" href={termsUrl} className={aStyle}>
                {t('common:termsconditions')}
              </a>
            </li>
          )}
          <li className="mr-4">
            <a target="_blank" href={privacyUrl} className={aStyle}>
              {t('common:privacy')}
            </a>
          </li>
          <li className="mr-4">
            <LanguageSelectDropdown />
          </li>
          <a>
            <ResetData />
          </a>
        </ul>
      </div>
    </>
  );
};

export default DefaultFooter;
