import React, { useCallback, useContext, useState } from 'react';
import { VyLoader } from '../components/common/VyLoader';

interface LoaderProviderProps {
  defaultTimeout?: number;
  defaultBackgroundColor?: string;
  defaultLoaderColor?: string;
  defaultBackgroundSolid?: boolean;
  children?: JSX.Element;
}

interface Context {
  start: (options?: StartOptions) => void;
  stop: () => void;
}

interface StartOptions {
  timeout?: number;
  backgroundSolid?: boolean;
  backgroundColor?: string;
  loaderColor?: string;
}

const LoaderContext = React.createContext<Context>({
  start: () => {},
  stop: () => {},
});

/**
 * @param defaultTimeout the timeout duration in ms
 * @param defaultBackgroundColor the default background color for the loader
 * @param defaultBackgroundSolid wether the backrgoudn is solid by deafault
 * @param defaultLoaderColor the color of the loader spinner
 */
export default function LoaderProvider({
  defaultTimeout = 20000,
  defaultBackgroundColor = 'var(--vystem-blue)',
  defaultLoaderColor = 'white',
  defaultBackgroundSolid = false,
  children,
}: LoaderProviderProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [solidBackground, setSolidBackground] = useState<boolean>(defaultBackgroundSolid);
  const [backgroundColor, setBackgroundColor] = useState<(string & {}) | undefined>(defaultBackgroundColor);
  const [loaderColor, setLoaderColor] = useState<(string & {}) | undefined>(defaultLoaderColor);

  const start = useCallback((options: StartOptions | undefined) => {
    setLoading(true);
    setSolidBackground(options?.backgroundSolid || false);
    setLoaderColor(options?.loaderColor || defaultLoaderColor);
    setBackgroundColor(options?.backgroundColor || defaultBackgroundColor);
    if (options?.timeout || defaultTimeout)
      setTimeout(
        () => {
          setLoading(false);
          setSolidBackground(false);
          setBackgroundColor(defaultBackgroundColor);
        },
        options?.timeout ? options.timeout : defaultTimeout
      );
  }, []);
  const stop = useCallback(() => {
    setLoading(false);
    setSolidBackground(false);
    setBackgroundColor(defaultBackgroundColor);
  }, []);

  return (
    <>
      {loading ? <VyLoader solidBackground={solidBackground} backgroundColor={backgroundColor} loaderColor={loaderColor} /> : <></>}
      <LoaderContext.Provider value={{ start, stop }}>{children}</LoaderContext.Provider>
    </>
  );
}

export const useLoader = () => {
  const ctx = useContext(LoaderContext);

  if (!ctx) {
    throw Error('The `useLoader` hook must be called from a descendent of the `LoaderProvider`.');
  }

  return { startLoader: ctx.start, stopLoader: ctx.stop };
};
