import { isAfter } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { EventInterface, User } from '../interfaces/interfaces';
import { setEventRegistrationId, setUser } from '../reduxToolkit/slices/commonSlice';
import { useSelector } from '../reduxToolkit/typedSelector';
import { checkEventAuth, getAuthData, registerAttendee } from '../services/services';
import { getToken, setToken } from '../utils/auth';
import { getNextLocaleCookie } from '../utils/functions';
import { useLanguage } from './useLanguage';

/**
 * Event access check functionalities
 */
export function useEventAccess() {
  const user = useSelector((state) => state.common.user);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { changeLanguage } = useLanguage();

  /**
   * Check if user data already exist / or can be loaded
   */
  async function loadUserData(): Promise<User | null> {
    const accessToken = getToken();
    let userObject = user;
    if (accessToken) {
      const data = await getAuthData();
      if (data?.data?.user) {
        userObject = data.data.user;
        dispatch(setUser(userObject));
        dispatch(setEventRegistrationId(data.data.registrationId));
      }
    }

    return userObject;
  }

  /**
   * Check if the event can be accessed by the current user
   * @param event
   * @param user
   * @returns status or redirect action
   */
  async function checkEventAccessible(
    event: EventInterface,
    user?: User | null,
    redirectOnLanguageMismatch?: boolean
  ): Promise<boolean | 'signup' | 'landing'> {
    if (!event) throw new Error('Event not defined but access function called');

    if (!event.settings.allowAccessToLive) {
      if (!user) {
        return 'landing';
      }
      const auth = await checkEventAuth(event._id);
      if (auth.data.status === false || auth.data.role === 'attendee') return 'landing';
    }

    if (event.eventauth?.mustRegister === true) {
      if (!user) {
        return 'signup';
      }
      const auth = await checkEventAuth(event._id);
      dispatch(setEventRegistrationId(auth?.data?.registration?._id));
      const nextLocaleCookie = getNextLocaleCookie(document?.cookie);

      if (auth.data.status === false) return 'signup';
      if (auth.data.language && auth.data.language !== i18n.language && nextLocaleCookie !== i18n.language) {
        console.log(`Redirecting to language ${auth.data.language} from ${i18n.language} with cookielanguage ${nextLocaleCookie}`);
        await changeLanguage(auth.data.language);
      }
    }

    return true;
  }

  const checkEventDisabled = (event: EventInterface | null) => {
    const isEventDisabled =
      event?.settings.disableEventAccessAfter === true || (event?.settings.autoDisableEventAccess && isAfter(new Date(), new Date(event?.datetime_end)));
    return isEventDisabled;
  };

  // when allowsRandomUser is activated, this function creates a random user on event load
  // check loadData in useDataLoader
  async function createRandomEventUser(eventId: string) {
    try {
      const email = `eventuser-${Date.now()}@event.vystem.io`;
      const name = `eventuser-${Date.now()}`;

      const res = await registerAttendee({ eventid: eventId, email, name });
      const { accessToken } = res.data;

      setToken(accessToken);
      return await loadUserData();
    } catch (err) {
      console.error(err);
    }
  }

  return {
    checkEventAccessible,
    loadUserData,
    createRandomEventUser,
    checkEventDisabled,
  };
}
