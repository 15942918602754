import Head from 'next/head';
import { useSelector } from '../../reduxToolkit/typedSelector';
import SupportTool from './SupportTool';

export const SupportHeader = (props: { target: 'event' | 'meeting' }) => {
  const { target } = props;

  const meeting = useSelector(({ video }) => video?.meeting);
  const event = useSelector((state) => state.common.event ?? state.admin.event);

  return (
    <>
      <Head>
        {target === 'event' && event?.organization?.integrations?.intercom?.showIntercomSupport !== false && intercomSupport}
        {target === 'meeting' &&
          meeting &&
          (meeting?.event?.organization?.integrations?.intercom?.showIntercomSupport !== false ||
            meeting.booth?.event?.organization?.integrations?.intercom?.showIntercomSupport !== false) &&
          intercomSupport}
      </Head>
      <SupportTool />
    </>
  );
};

const intercomSupport = (
  <script
    dangerouslySetInnerHTML={{
      __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/cbam6m39';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
    }}
  />
);
