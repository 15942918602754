import { Paper } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { RingLoader, ScaleLoader } from 'react-spinners';
import { useSelector } from '../../reduxToolkit/typedSelector';
import { LinkWithA } from './LinkWithA';

/**
 * @params solidBackground for not having any transparancy in the loader
 * @params backgroundColor the color in the background of the loader
 * @params loaderColor the color of the spinner loader
 * @params redirectUri if specified a button to directly redirect is shown below the spinner
 */
interface VyLoaderProps {
  solidBackground?: boolean;
  backgroundColor?: string;
  loaderColor?: string;
  redirectUri?: string;
  useRingLoader?: boolean;
  onlyPartial?: boolean;
}

export const VyLoader: React.FC<VyLoaderProps> = (props) => {
  const { loaderColor = 'black', backgroundColor, solidBackground, redirectUri, useRingLoader, onlyPartial = false } = props;

  //Event might be set due to router.push actions
  const event = useSelector((state) => state.common.event);
  const { t } = useTranslation();

  if (onlyPartial) {
    if (redirectUri) console.error('redirectUri not implemented in partial loader');

    return (
      <div
        className={`flex flex-col justify-center items-center w-full h-full `}
        style={{ backgroundColor: solidBackground ? backgroundColor || event?.template?.mainColor || 'white' : 'rgba(0, 0, 0, 0.5)' }}>
        {!useRingLoader ? (
          <ScaleLoader color={loaderColor || event?.template?.mainButtonTextColor} />
        ) : (
          <RingLoader color={loaderColor || event?.template?.mainButtonTextColor} />
        )}
      </div>
    );
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full h-screen flex flex-col justify-center items-center z-[1300]`}
      style={{ backgroundColor: solidBackground ? backgroundColor || event?.template?.mainColor || 'white' : 'rgba(0, 0, 0, 0.5)' }}>
      {!useRingLoader ? (
        <ScaleLoader color={loaderColor || event?.template?.mainButtonTextColor} />
      ) : (
        <RingLoader color={loaderColor || event?.template?.mainButtonTextColor} />
      )}
      {redirectUri && (
        <Paper elevation={2} className="flex flex-col items-center p-4">
          <span>{t('event:no-auto-redir')}</span>
          <LinkWithA href={redirectUri}>
            <p className="p-0 m-0 text-blue-400 no-underline hover:underline">{t('event:no-auto-redir-click')}</p>
          </LinkWithA>
        </Paper>
      )}
    </div>
  );
};
