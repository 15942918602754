export enum QueueInviteStatus {
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
  ACCEPT = 'accept',
  REJECT = 'reject',
  REQUEUE = 'requeue',
  TIMEOUT = 'timeout',
  ACCEPTTIMEOUT = 'accepttimeout',
}

export enum CALLINGSTATE {
  CALLING,
  BEINGCALLED,
  CONNECTED,
  CANCELED,
  ENDED,
  IDLE,
  HANDLED, // Handled from another tab, so just close the modal without ending the call
}

export enum SPEEDDATINGSTATE {
  INACTIVE,
  WAITING,
  CONNECTING,
  CONNECTED,
  ENDING,
}
