export const VyBackgroundVideoLoop = () => {
  return (
    <video
      className=""
      autoPlay
      muted
      loop
      style={{ position: 'absolute', right: 0, bottom: 0, minWidth: '100%', minHeight: '100%', zIndex: -30, objectFit: 'cover' }}
      src="https://stream.mux.com/LGodXAMKR6for3902016uON8KK2McWERG02NecT00ENq7j00/high.mp4"
    />
  );
};
