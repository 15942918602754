import { createSlice } from '@reduxjs/toolkit';
import { LayoutContainer } from 'opentok-layout-js';
import { JOINSTATE } from '../../components/meeting/hooks/useJoin';
import {
  AppointmentState,
  ContactShare,
  media,
  Meetingroom,
  OrientationState,
  QueueEntry,
  QueueState,
  Sidebar,
  UpcomingAppointment,
  User,
} from '../../interfaces/interfaces';

// Please keep in alphabetical order

export interface VideoEffectOptions {
  useBackgroundEffect: boolean;
  backgroundEffectMode: 'blur' | 'image';
  backgroundImageUrl: string | null;
  blurAmount: number;
  showUsernameBanner: boolean;
  backgroundImageData?: any;
}
export interface VideoState {
  isAuthorized: boolean;
  isMuted: boolean;
  isVideoActive: boolean;
  image?: any;
  imagePublisher?: OT.Publisher;
  isPlaying: boolean;
  layout?: LayoutContainer;
  media?: media;
  publisher?: OT.Publisher;
  session?: OT.Session;
  showLeaveModal: boolean;
  userProfileModal: any;
  vonageToken?: string;
  showQaModal: boolean;
  sidebar: Sidebar;
  meeting?: Meetingroom;
  pdf?: any;
  pdfPublisher?: OT.Publisher;
  token?: string;
  username?: string;
  video?: HTMLVideoElement;
  videoPublisher?: OT.Publisher;
  participants: OT.Connection[];
  streams: OT.Stream[];
  showKnockModal?: string; //userid
  showDevicePreview: boolean;
  showLogin: boolean;
  showKnock: boolean;
  showPresentQuestion: boolean;
  showBackToEvent: boolean;
  showJoinDenied: boolean;
  showTechCheck: boolean;
  joinState: JOINSTATE;
  queue: QueueState;
  videoEffect?: VideoEffectOptions;
  sendMessageToMatchingInvite: boolean;
  messageToMatchingInvite?: string;
  orientation: OrientationState;
  showContactShareModal: boolean;
  hasSharedContact: boolean;
  hasRaisedHand: boolean;
  raisedHands: string[];
  canSendExternalChatLink: boolean;
  showQrModal: boolean;
  showLikeButton: boolean;
  archiving: boolean;
  showStreamOptionsModal: boolean;
  contentShareActive: boolean;
  contentSharePublisher?: OT.Publisher;
  playKnockSound: boolean;
  playRaiseHandSound: boolean;
  meetingError?: string;
  videoIds: string[];
  breakoutRooms: Meetingroom[];
  breakoutRoomsUsers: User[];
  showBreakoutReturnModal: boolean;
  dataShares: ContactShare[];
  selectedDataShare?: ContactShare;
  showDevicePermissionModal: boolean;
  allUsersEnabled: boolean;
  answeredPollQuestions: string[];
  queueForwardModalEntry: QueueEntry | null;
  appointments: AppointmentState;
  upcomingAppointmentModal: UpcomingAppointment;
  playJoinSound: boolean;
  showDeviceHelpModal: boolean;
  presetPassword?: string;
  renderingInterval?: ReturnType<typeof setTimeout>;
  showToggleRecordingModal?: boolean;
  datetimeJoined?: Date;
}

const initialState: VideoState = {
  isAuthorized: false,
  isMuted: false,
  isVideoActive: true,
  image: undefined,
  imagePublisher: undefined,
  isPlaying: false,
  layout: undefined,
  media: undefined,
  publisher: undefined,
  sidebar: { mode: null },
  showLeaveModal: false,
  showQaModal: false,
  userProfileModal: undefined,
  pdf: undefined,
  pdfPublisher: undefined,
  session: undefined,
  meeting: undefined,
  vonageToken: undefined,
  token: undefined,
  username: undefined,
  video: undefined,
  videoPublisher: undefined,
  participants: [],
  streams: [],
  showKnockModal: undefined,
  showDevicePreview: false,
  showLogin: false,
  showKnock: false,
  showPresentQuestion: false,
  showJoinDenied: false,
  showBackToEvent: false,
  showTechCheck: false,
  joinState: JOINSTATE.LOADING,

  queue: {
    entries: [],
    isInviting: false,
    isLoading: false,
    inviteStatus: null,
    failureErrorKey: null,
    invitingUserObject: null,
    canInvite: true,
    soundOnQueueJoin: true,
  },
  videoEffect: {
    useBackgroundEffect: false,
    backgroundEffectMode: 'blur',
    blurAmount: 15,
    backgroundImageUrl: null,
    showUsernameBanner: false,
    backgroundImageData: null,
  },
  sendMessageToMatchingInvite: false,
  messageToMatchingInvite: undefined,
  orientation: {
    timeLeft: 0,
    canInvite: true,
    isLoading: false,
    isInviting: false,
    potentialMatches: null,
    filter: [],
    invitingUserObject: {
      name: null,
      _id: null,
      response: null,
    },
  },
  showContactShareModal: false,
  hasSharedContact: false,
  hasRaisedHand: false,
  raisedHands: [],
  canSendExternalChatLink: true,
  showQrModal: false,
  showLikeButton: false,
  archiving: false,
  showStreamOptionsModal: false,
  contentShareActive: false,
  contentSharePublisher: undefined,
  playKnockSound: true,
  playRaiseHandSound: true,
  meetingError: undefined,
  videoIds: [],
  breakoutRooms: [],
  breakoutRoomsUsers: [],
  showBreakoutReturnModal: false,
  dataShares: [],
  showDevicePermissionModal: false,
  allUsersEnabled: false,
  answeredPollQuestions: [],
  queueForwardModalEntry: null,
  appointments: {
    entries: [],
    isInviting: false,
    currentAppointment: null,
  },
  upcomingAppointmentModal: {
    show: false,
    onConfirm: () => {},
  },
  playJoinSound: true,
  showDeviceHelpModal: false,
  presetPassword: undefined,
  renderingInterval: undefined,
  datetimeJoined: undefined,
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    resetVideo: (state, action) => initialState,
    setIsMuted: (state, action) => {
      state.isMuted = action.payload;
    },
    setVideoActive: (state, action) => {
      state.isVideoActive = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setImagePublisher: (state, action) => {
      state.imagePublisher = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setLayout: (state, action) => {
      state.layout = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
    setPdf: (state, action) => {
      state.pdf = action.payload;
    },
    setPdfPublisher: (state, action) => {
      state.pdfPublisher = action.payload;
    },
    setPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setShowLeaveModal: (state, action) => {
      state.showLeaveModal = action.payload;
    },
    setSidebarMode: (state, action) => {
      state.sidebar.mode = action.payload;
    },
    setShowQaModal: (state, action) => {
      state.showQaModal = action.payload;
    },
    setMeeting: (state, action) => {
      state.meeting = action.payload;
      state.breakoutRooms = action.payload?.breakoutrooms;
      state.breakoutRoomsUsers = action?.payload?.breakoutrooms?.map((room: any) => room.users).flat(1);
    },
    setVonageToken: (state, action) => {
      state.vonageToken = action.payload;
    },
    setVideo: (state, action) => {
      state.video = action.payload;
    },
    setVideoPublisher: (state, action) => {
      state.videoPublisher = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setStreams: (state, action) => {
      state.streams = action.payload;
    },
    setUserProfileModal: (state, action) => {
      state.userProfileModal = action.payload;
    },
    setShowKnockModal: (state, action) => {
      state.showKnockModal = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setShowKnock: (state, action) => {
      state.showKnock = action.payload;
    },
    setShowPresentQuestion: (state, action) => {
      state.showPresentQuestion = action.payload;
    },
    setShowJoinDenied: (state, action) => {
      state.showJoinDenied = action.payload;
    },
    setShowDevicePreview: (state, action) => {
      state.showDevicePreview = action.payload;
    },
    setJoinState: (state, action) => {
      state.joinState = action.payload;
    },
    setQueueEntries: (state, action) => {
      state.queue.entries = action.payload;
    },
    setQueueInviting: (state, action) => {
      state.queue.isInviting = action.payload.isInviting;
      if (action.payload.user) state.queue.invitingUserObject = action.payload.user;
      state.queue.failureErrorKey = null;
    },
    setQueueInvitingObject: (state, action) => {
      state.queue.invitingQueueItem = action.payload;
    },
    setQueueInviteStatus: (state, action) => {
      state.queue.inviteStatus = action.payload;
    },
    setQueueLoading: (state, action) => {
      state.queue.isLoading = action.payload;
    },
    setQueueFailureErrorKey: (state, action) => {
      state.queue.failureErrorKey = action.payload;
    },
    setCanQueueInvite: (state, action) => {
      state.queue.canInvite = action.payload;
    },
    setSoundOnQueueJoin: (state, action) => {
      state.queue.soundOnQueueJoin = action.payload;
    },
    setShowBackToEvent: (state, action) => {
      state.showBackToEvent = action.payload;
    },
    setSendMessageToMatchingInvite: (state, action) => {
      state.sendMessageToMatchingInvite = action.payload;
    },
    setMessageToMatchingInvite: (state, action) => {
      state.messageToMatchingInvite = action.payload;
    },
    setOrientationLoading: (state, action) => {
      state.orientation.isLoading = action.payload;
    },
    setOrientationEntries: (state, action) => {
      state.orientation.potentialMatches = action.payload;
    },
    setOrientationInviting: (state, action) => {
      state.orientation.isInviting = action.payload;
    },
    setOrientationFilter: (state, action) => {
      state.orientation.filter = action.payload;
    },
    setOrientationCanInvite: (state, action) => {
      state.orientation.canInvite = action.payload;
    },
    setOrientationTimeLeft: (state, action) => {
      state.orientation.timeLeft = action.payload;
    },
    setOrientationUser: (state, action) => {
      state.orientation.invitingUserObject = action.payload;
    },
    setOrientationResponse: (state, action) => {
      state.orientation.invitingUserObject.response = action.payload;
    },
    setShowContactShareModal: (state, action) => {
      state.showContactShareModal = action.payload;
    },
    setHasSharedContact: (state, action) => {
      state.hasSharedContact = action.payload;
    },
    setHasRaisedHand: (state, action) => {
      state.hasRaisedHand = action.payload;
    },
    setRaisedHands: (state, action) => {
      state.raisedHands = action.payload;
    },
    setCanSendExternalChatLink: (state, action) => {
      state.canSendExternalChatLink = action.payload;
    },
    setShowQrModal: (state, action) => {
      state.showQrModal = action.payload;
    },
    setArchiving: (state, action) => {
      state.archiving = action.payload;
    },
    setVideoEffect: (state, action) => {
      state.videoEffect = action.payload;
    },
    setShowLikeButton: (state, action) => {
      state.showLikeButton = action.payload;
    },
    setShowStreamOptionsModal: (state, action) => {
      state.showStreamOptionsModal = action.payload;
    },
    setContentShareActive: (state, action) => {
      state.contentShareActive = action.payload;
    },
    setContentSharePublisher: (state, action) => {
      state.contentSharePublisher = action.payload;
    },
    setPlayKnockSound: (state, action) => {
      state.playKnockSound = action.payload;
    },
    setPlayRaiseHandSound: (state, action) => {
      state.playRaiseHandSound = action.payload;
    },
    setMeetingError: (state, action) => {
      state.meetingError = action.payload;
    },
    setVideoIds: (state, action) => {
      state.videoIds = action.payload;
    },
    setBreakoutRooms: (state, action) => {
      state.breakoutRooms = action.payload;
      state.breakoutRoomsUsers = action?.payload?.map((room: any) => room.users).flat(1);
    },
    setBreakoutRoomsUsers: (state, action) => {
      state.breakoutRoomsUsers = action.payload;
    },
    setShowBreakoutReturnModal: (state, action) => {
      state.showBreakoutReturnModal = action.payload;
    },
    setSharedDataNotes: (state, action) => {
      state.dataShares = action.payload;
    },
    setNewSharedDataNote: (state, action) => {
      state.dataShares.push(action.payload);
    },
    setShowDevicePermissionModal: (state, action) => {
      state.showDevicePermissionModal = action.payload;
    },
    setSelectedDataShare: (state, action) => {
      state.selectedDataShare = action.payload;
    },
    setShowTechCheck: (state, action) => {
      state.showTechCheck = action.payload;
    },
    setAllUsersEnabled: (state, action) => {
      state.allUsersEnabled = action.payload;
    },
    setAnsweredPollQuestions: (state, action) => {
      state.answeredPollQuestions = action.payload;
    },
    setQueueForwardModalEntry: (state, action) => {
      state.queueForwardModalEntry = action.payload;
    },
    setAppointmentEntries: (state, action) => {
      state.appointments.entries = action.payload;
    },
    setAppointmentIsInviting: (state, action) => {
      state.appointments.isInviting = action.payload;
    },
    setCurrentAppointment: (state, action) => {
      state.appointments.currentAppointment = action.payload;
    },
    setUpcomingAppointment: (state, action) => {
      state.upcomingAppointmentModal = action.payload;
    },
    setPlayJoinSound: (state, action) => {
      state.playJoinSound = action.payload;
    },
    setShowDeviceHelpModal: (state, action) => {
      state.showDeviceHelpModal = action.payload;
    },
    setPresetPassword: (state, action) => {
      state.presetPassword = action.payload;
    },
    setRenderingInterval: (state, action) => {
      state.renderingInterval = action.payload;
    },
    setShowToggleRecordingModal: (state, action) => {
      state.showToggleRecordingModal = action.payload;
    },
    setDatetimeJoined: (state, action) => {
      state.datetimeJoined = action.payload;
    },
  },
});

export const {
  resetVideo,
  setIsMuted,
  setVideoActive,
  setImage,
  setImagePublisher,
  setIsPlaying,
  setLayout,
  setMedia,
  setPublisher,
  setSession,
  setShowLeaveModal,
  setSidebarMode,
  setPdf,
  setPdfPublisher,
  setShowQaModal,
  setMeeting,
  setVonageToken,
  setUsername,
  setVideo,
  setVideoPublisher,
  setParticipants,
  setStreams,
  setUserProfileModal,
  setShowKnockModal,
  setIsAuthorized,
  setShowLogin,
  setShowKnock,
  setShowPresentQuestion,
  setShowJoinDenied,
  setJoinState,
  setQueueEntries,
  setQueueInviting,
  setQueueLoading,
  setQueueInviteStatus,
  setQueueFailureErrorKey,
  setQueueInvitingObject,
  setCanQueueInvite,
  setShowDevicePreview,
  setOrientationFilter,
  setShowBackToEvent,
  setSendMessageToMatchingInvite,
  setMessageToMatchingInvite,
  setOrientationLoading,
  setOrientationEntries,
  setOrientationInviting,
  setOrientationCanInvite,
  setOrientationTimeLeft,
  setOrientationUser,
  setOrientationResponse,
  setShowContactShareModal,
  setHasSharedContact,
  setHasRaisedHand,
  setRaisedHands,
  setCanSendExternalChatLink,
  setShowQrModal,
  setArchiving,
  setVideoEffect,
  setShowLikeButton,
  setShowStreamOptionsModal,
  setContentShareActive,
  setContentSharePublisher,
  setPlayKnockSound,
  setPlayRaiseHandSound,
  setMeetingError,
  setVideoIds,
  setBreakoutRooms,
  setBreakoutRoomsUsers,
  setShowBreakoutReturnModal,
  setSharedDataNotes,
  setNewSharedDataNote,
  setShowDevicePermissionModal,
  setSelectedDataShare,
  setShowTechCheck,
  setAllUsersEnabled,
  setAnsweredPollQuestions,
  setQueueForwardModalEntry,
  setAppointmentEntries,
  setAppointmentIsInviting,
  setCurrentAppointment,
  setUpcomingAppointment,
  setPlayJoinSound,
  setShowDeviceHelpModal,
  setPresetPassword,
  setRenderingInterval,
  setShowToggleRecordingModal,
  setDatetimeJoined,
} = videoSlice.actions;

export default videoSlice.reducer;
