import axios from 'axios';
import { delete_cookie } from '../services/helper';
import { getQueryParam } from './functions';
import serverlessRequest from './serverlessRequest';

const ACCESS_TOKEN = 'access_token';
const EVENT_TOKEN = 'event_token';
const ROLE = 'role';

export const setToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  axios.defaults.headers.common.authorization = `Bearer ${token}`;
  serverlessRequest.defaults.headers.common.authorization = `Bearer ${token}`;
};

export const getToken = (type: 'access_token' | 'event_token' = 'access_token') => {
  return localStorage.getItem(type);
};

export const setTokenFromLocalStorage = () => {
  const token = getToken();
  if (token) setToken(token);
};

export const setTokenFromQueryParameters = () => {
  const accessToken = getQueryParam('authtoken');

  if (accessToken !== false) {
    setToken(accessToken.toString());
  }
};
/**
 * Workaround function due to 403 issue on frontend
 */
export const isTokenSet = () => {
  return axios.defaults.headers?.common.authorization && axios.defaults.headers?.common.authorization.includes('Bearer');
};

export const removeToken = () => {
  localStorage.clear();
  axios.defaults.headers.common.authorization = '';
  serverlessRequest.defaults.headers.common.authorization = '';
};

export const logout = () => {
  removeToken();
  delete_cookie('sde.token');
  delete_cookie('sde.mdata');
};

export const login = (token: string, roleLevel: string) => {
  setToken(token);
  localStorage.setItem(ROLE, roleLevel);
};

export const eventLogin = (eventId: string) => {
  localStorage.setItem(EVENT_TOKEN, eventId);
};

export const isLoggedInToEvent = (eventId: string) => {
  return eventId === getToken('event_token');
};
