import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EmbeddedPrivileges, menuItem, PlatformPrivileges, Privilege, PrivilegeTypes, User } from '../interfaces/interfaces';
import { setRequiredPrivilege } from '../reduxToolkit/slices/adminSlice';
import { fetchAndSetBoothPrivilegedUsers, fetchAndSetOwnEventPrivilegeRequests, fetchAndSetUserPrivilege } from '../reduxToolkit/slices/commonSlice';
import { useSelector } from '../reduxToolkit/typedSelector';
import { createPrivilegeRequest, getAllUsersPrivileges, getOrgUsers, updatePrivilege } from '../services/services';

interface FilteredPrivilege {
  [key: string]: {
    accessible: User[];
    embedded?: { [key: string]: User[] };
    [key: string]: User[] | { [key: string]: User[] } | { accessible: User[]; embedded: { [key: string]: User[] } } | undefined;
  };
}

export function usePrivilege(menuItems?: menuItem[], useCommonEventState?: boolean) {
  // states
  const [orgUsers, setOrgUsers] = useState<User[]>();
  const [filteredPrivileges, setFilteredPrivileges] = useState<FilteredPrivilege>();

  // selectors
  const userPrivilege = useSelector((state) => state.common.privilege);
  const boothPrivilegedUsers = useSelector((state) => state.common.boothPrivilegedUsers);
  const event = useSelector((state) => state.common.event ?? state.admin.event);
  const booth = useSelector((state) => state.admin.userbooth);
  const user = useSelector((state) => state.common.user);
  const ownPrivilegeRequests = useSelector((state) => state.common.ownPrivilegeRequests);
  const menuItemPrivileges = useSelector((state) => state.common.menuItemPrivileges);

  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (menuItems) findAndStorePrivilege();
  }, [router.asPath, menuItems]);

  /**
   *
   * @param eventId
   * @description Fetch all users privileges and set privileges as keys and assigned users as values
   */
  async function filterPrivileges(eventId: string) {
    const allprivilegesData = await getAll(eventId);
    const allprivileges = allprivilegesData?.filter((p) => !p.user?.datetime_deleted && p.user?.role !== 'ORGANIZATION_ADMIN').filter((p) => !!p.settings);
    const obj: FilteredPrivilege = {};

    let sortedPrivileges: string[] = [];
    const privilegesOutsideMenuItems: string[] = [];

    Object.keys(PrivilegeTypes)
      .filter((x) => !(parseInt(x) >= 0))
      .map((a) => {
        if (menuItemPrivileges.indexOf(a) >= 0) sortedPrivileges[menuItemPrivileges.indexOf(a)] = a;
      });

    Object.keys(PlatformPrivileges)
      .filter((x) => !(parseInt(x) >= 0))
      .map((a) => {
        privilegesOutsideMenuItems.push(a);
      });

    Object.keys(EmbeddedPrivileges)
      .filter((x) => !(parseInt(x) >= 0))
      .map((a) => {
        privilegesOutsideMenuItems.push(a);
      });

    sortedPrivileges = [...sortedPrivileges, ...privilegesOutsideMenuItems].filter((priv) => !!priv);
    sortedPrivileges.map((setting: string) => {
      const path = setting.split('.');

      // set main privilege if it's not there already before everything
      if (!obj[path[0]]) obj[path[0]] = { accessible: [], embedded: {} };
      // check for subprivilege, like guestmanagement.settings
      if (path.length == 2) obj[path[0]][path[1]] = { accessible: [] };
      // add embedded privileges here
      else if (path.length !== 1) _.set(obj, path, []);

      allprivileges?.map((privilege: Privilege) => {
        if (path.length === 1 && privilege.settings[path[0] as any]?.accessible) {
          obj[path[0]].accessible.push(privilege.user);
          return;
        }
        if (path.length > 1 && _.get(privilege.settings, path) === true) {
          const old = _.get(obj, path) || [];
          _.set(obj, path, [...old, privilege.user]);
        } else if (path.length > 1 && _.get(privilege.settings, path)?.accessible) {
          const old = _.get(obj, [...path, 'accessible']) || [];
          _.set(obj, [...path, 'accessible'], [...old, privilege.user]);
        }
      });
    });
    setFilteredPrivileges(obj);
  }

  /**
   *
   * @param eventId
   * @param orgId
   * @description Fetch organization users (that can have privileges) and filter privileges for dashboard
   */
  async function initAdminPrivileges(eventId: string, orgId: string) {
    const users = await getOrgUsers(orgId);
    setOrgUsers(users?.filter((u: User) => u.role === 'ORGANIZATION_SUBUSER'));
    await filterPrivileges(eventId);
  }

  /**
   *
   * @param eventId
   * @description Fetch own privileges
   */
  async function initUserPrivileges(eventId: string) {
    dispatch(fetchAndSetUserPrivilege(eventId));
  }

  async function initUserBoothPrivilege(boothId: string) {
    dispatch(fetchAndSetBoothPrivilegedUsers(boothId));
  }

  /**
   *
   * @param eventId
   * @description Fetch all privileges
   */
  async function getAll(eventId: string) {
    const privileges = await getAllUsersPrivileges(eventId);
    return privileges;
  }

  /**
   *
   * @param userId
   * @param eventId
   * @param updateObj
   * @description update a user's privilege
   */
  async function update(userId: string, eventId: string, updateObj?: { [key: string]: boolean }, allAccess?: boolean) {
    const privileges = await updatePrivilege(userId, eventId, updateObj, undefined, undefined, allAccess);
    return privileges;
  }

  async function sendPrivilegeRequest(privilege: keyof typeof PrivilegeTypes) {
    if (event) {
      await createPrivilegeRequest(event._id, privilege);
      dispatch(fetchAndSetOwnEventPrivilegeRequests(event._id));
      toast.success(t('common:success'));
    }
  }

  /**
   *
   * @param privilege
   * @description checks if the current user has acces to specific page
   * @returns
   * 'PENDING' when waiting for event and privileges to be initialized -> display loader
   * 'ACCESS_APPROVED' when user has access or when no privilege is required for that page -> display page
   * 'ACESS_DENIED' when user has no access -> display access denied page
   */
  const hasAccess = (privilege?: keyof typeof PrivilegeTypes, isEmbedded = false): 'PENDING' | 'ACCESS_APPROVED' | 'ACCESS_DENIED' => {
    if (user?.role === 'ORGANIZATION_ADMIN') return 'ACCESS_APPROVED';

    if (!event && !booth) return 'PENDING';

    if (!!booth && router.pathname.includes('/account/booth')) return _checkBoothAccess();

    if (!userPrivilege) return 'PENDING';

    return event?.modules.privileges.active && privilege && user?.role === 'ORGANIZATION_SUBUSER'
      ? checkHigherPrivilege(`${privilege}`)
        ? 'ACCESS_APPROVED'
        : 'ACCESS_DENIED'
      : 'ACCESS_APPROVED';
  };

  const _checkBoothAccess = (): 'ACCESS_APPROVED' | 'ACCESS_DENIED' | 'PENDING' => {
    if (!boothPrivilegedUsers) return 'PENDING';

    return boothPrivilegedUsers.find((priv) => priv._id === user?._id) !== undefined ? 'ACCESS_APPROVED' : 'ACCESS_DENIED';
  };

  /**
   *
   * @param privilege
   * @description checks if main privilege is active. if not it checks for sub privileges
   */
  const checkHigherPrivilege = (privilege: string) => {
    // check on embedded privileges as they do not depend on higher privilege
    if (!privilege.includes('embedded')) {
      const mainPrivilege = privilege.split('.')[0];
      return _.get(userPrivilege?.settings, mainPrivilege || '')?.accessible || _.get(userPrivilege?.settings, privilege || '')?.accessible;
    }
    return _.get(userPrivilege?.settings, privilege || '');
  };

  /**
   *
   * @param menuItem
   * @description checks for nested privilege e.g profilefields in guestmanagement
   * if at least 1 nested privilege is active, then returns true
   */
  const checkNestedPrivilege = (menuItem: menuItem) => {
    // this check only applies for menus with children
    if (menuItem.children?.length) {
      for (const child of menuItem.children) {
        if (hasAccess(child.privilege) === 'ACCESS_APPROVED') return true;
      }
      // it returns false after checking all submenus privileges with no success
      return false;
    } else return hasAccess(menuItem.privilege) === 'ACCESS_APPROVED';
  };

  /**
   *
   * @param privilege
   * @description checks if user can request a privilege
   */
  const canRequestAccess = (privilege?: keyof typeof PrivilegeTypes): 'DISABLED' | 'CAN_REQUEST' | 'ALREADY_REQUESTED' => {
    if (!(event && event?.modules.privileges.active && !booth && user?.role !== 'ORGANIZATION_ADMIN')) return 'DISABLED';
    return ownPrivilegeRequests?.find((request) => request.privilege === privilege) ? 'ALREADY_REQUESTED' : 'CAN_REQUEST';
  };

  /**
   *
   * @param item is the menu item that tells if this page requires privilege or not
   * @description Finds the required privilege and store it
   * Required privilege is later used for PrivilegePageWrapper to display/hide the current page
   */
  function findAndStorePrivilege() {
    menuItems?.map((item) => {
      const { href, privilege, children } = item;
      if (router.asPath === href && !children) {
        dispatch(setRequiredPrivilege(privilege));
      } else if (children && children.length > 0)
        children.map((child) => {
          if (`${href}${child.subhref}` === router.asPath) {
            dispatch(setRequiredPrivilege(child.privilege));
          }
        });
    });
  }

  return {
    initAdminPrivileges,
    initUserPrivileges,
    getAll,
    update,
    hasAccess,
    findAndStorePrivilege,
    checkNestedPrivilege,
    initUserBoothPrivilege,
    sendPrivilegeRequest,
    canRequestAccess,
    orgUsers,
    filteredPrivileges,
  };
}
