import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { EventInterface } from '../interfaces/interfaces';
import { useSelector } from '../reduxToolkit/typedSelector';
import { getNextLocaleCookie } from '../utils/functions';

export function useLanguage() {
  const router = useRouter();
  const { i18n } = useTranslation();
  const supportedLanguages = ['en', 'de', 'fr'];
  const event = useSelector((state) => state.admin.event || state.common.event);

  const [isCheckingLanguage, setIsCheckingLanguage] = useState<boolean>(true);

  function setLangToCookie(lang: string) {
    document.cookie = `NEXT_LOCALE=${lang}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  }

  function _cleanRouteFromLocales(pathname: string): string {
    return pathname.includes('/fr/')
      ? pathname.replace('/fr/', '/')
      : pathname.includes('/en/')
      ? pathname.replace('/en/', '/')
      : pathname.replace('/de_formal/', '/');
  }

  async function changeLanguage(selectedLocale: string, redirect = true) {
    setLangToCookie(selectedLocale);
    try {
      await i18n.changeLanguage(selectedLocale);

      if (window && selectedLocale && redirect) {
        const cleanRoute = _cleanRouteFromLocales(window.location?.pathname);
        router.push(
          {
            pathname: cleanRoute,
            query: router.query,
          },
          undefined,
          { locale: selectedLocale }
        );
      }
    } catch (err) {
      console.error(`Language switch error ${err}`);
    }
  }

  function checkEventLanguageRedirect(event: EventInterface): boolean {
    if (!event) return false;

    const nextLocaleCookie = getNextLocaleCookie(document?.cookie);

    if (router.locale === 'de' && event?.isFormal && !router.asPath.includes('de_formal')) {
      changeLanguage('de_formal');
      return true;
    } else if (router.locale === 'de_formal' && event?.isFormal === false) {
      changeLanguage('de');
      return true;
    }

    // console.log(`Determining language based on eventlanguage (${event.language}), router locale (${router.locale}) and cookie (${nextLocaleCookie})`);
    if (router.locale && !event?.languages?.includes(router.locale) && nextLocaleCookie !== router.locale) {
      //@ts-ignore
      changeLanguage(event.languages[0]);
      return true;
    }

    if (!nextLocaleCookie && window && router.isReady) {
      try {
        const navigatorLanguage = window.navigator?.language;
        if (navigatorLanguage) {
          const match = supportedLanguages.find((l) => navigatorLanguage.includes(l));
          if (match && match !== router.locale) {
            changeLanguage(match);
            return true;
          }
        }
      } catch (err) {
        console.error('Language change error', err);
      }
    }

    return false;
  }

  function checkNavigatorLanguage(): void {
    setIsCheckingLanguage(true);
    const nextLocaleCookie = getNextLocaleCookie(document?.cookie);
    const navigatorLanguage = navigator?.language;

    if (nextLocaleCookie && router.locale !== nextLocaleCookie) {
      changeLanguage(nextLocaleCookie);
      return;
    }

    if (navigatorLanguage && !nextLocaleCookie && supportedLanguages.includes(navigator?.language) && router.locale !== navigatorLanguage) {
      changeLanguage(navigator.language);
      return;
    }

    setIsCheckingLanguage(false);
  }

  function getLanguage() {
    if (event?.languages && event.languages.length > 0) {
      if (router.locale && event?.languages.includes(router.locale)) return router.locale;
      if (event.languages[0] !== null) return event.languages[0];
    }
    return 'de';
  }

  return {
    setLangToCookie,
    changeLanguage,
    checkEventLanguageRedirect,
    checkNavigatorLanguage,
    getLanguage,
    isCheckingLanguage,
    supportedLanguages,
  };
}
