import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VbwState {
  signupVisible: boolean;
  hasRegistered: boolean;
  signupModalVisible: boolean;
}

const initialState: VbwState = {
  signupVisible: false,
  signupModalVisible: false,
  hasRegistered: false,
};

const vbwSlice = createSlice({
  name: 'vbw',
  initialState,
  reducers: {
    setSignupVisisble: (state, action: PayloadAction<boolean>) => {
      state.signupVisible = action.payload;
    },
    setHasRegistered: (state, action: PayloadAction<boolean>) => {
      state.hasRegistered = action.payload;
    },
    setSignupModalVisible: (state, action: PayloadAction<boolean>) => {
      state.signupModalVisible = action.payload;
    },
  },
});

export const { setSignupVisisble, setHasRegistered, setSignupModalVisible } = vbwSlice.actions;

export default vbwSlice.reducer;
