import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FAQProps } from '../../lib/faq/types';

export type UpdateContentType = { type?: 'section' | 'function' | 'submodule'; typeId?: string; sectionId?: string; functionId?: string; isPremium?: boolean };
export interface FunctionState {
  selectedVideoUrl?: string;
  selectedQaContent?: FAQProps[];
  selectedIdForQAUpdate?: string;
  updateContent?: UpdateContentType;
  showPageCreatePrompt: boolean;
  isTogglingFunction: boolean;
}

const initialState: FunctionState = {
  selectedVideoUrl: undefined,
  updateContent: undefined,
  selectedIdForQAUpdate: undefined,
  showPageCreatePrompt: false,
  isTogglingFunction: false,
};

const functionSlice = createSlice({
  name: 'setup',
  initialState,
  reducers: {
    setSelectedVideoUrl: (state, action: PayloadAction<string | undefined>) => {
      state.selectedVideoUrl = action.payload;
    },
    setSelectedQaContent: (state, action: PayloadAction<FAQProps[] | undefined>) => {
      state.selectedQaContent = action.payload;
    },
    setUpdateContent: (state, action: PayloadAction<UpdateContentType | undefined>) => {
      state.updateContent = action.payload;
    },
    setSelectedIdForQAUpdate: (state, action: PayloadAction<string | undefined>) => {
      state.selectedIdForQAUpdate = action.payload;
    },
    setShowPageCreatePrompt: (state, action) => {
      state.showPageCreatePrompt = action.payload;
    },
    setIsTogglingFunction: (state, action) => {
      state.isTogglingFunction = action.payload;
    },
  },
});

export const {
  setSelectedVideoUrl,
  setSelectedQaContent,
  setUpdateContent,
  setSelectedIdForQAUpdate,
  setShowPageCreatePrompt,
  setIsTogglingFunction,
} = functionSlice.actions;

export default functionSlice.reducer;
