import { createSlice } from '@reduxjs/toolkit';

export interface ChatState {
  isBannedFromChat: boolean;
  playChatSound: boolean;
  showNewMessageIndicatorInMeeting: boolean; // Only for /videochat, while users can only be in one meeting at the same time. Hence, a state in this slice is okay.
}

const initialState: ChatState = {
  isBannedFromChat: false,
  playChatSound: true,
  showNewMessageIndicatorInMeeting: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setIsBannedFromChat: (state, action) => {
      state.isBannedFromChat = action.payload;
    },
    setPlayChatSound: (state, action) => {
      state.playChatSound = action.payload;
    },
    setShowNewMessageIndicatorInMeeting: (state, action) => {
      state.showNewMessageIndicatorInMeeting = action.payload;
    },
  },
});

export const { setIsBannedFromChat, setPlayChatSound, setShowNewMessageIndicatorInMeeting } = chatSlice.actions;

export default chatSlice.reducer;
