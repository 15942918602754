import { useTranslation } from 'next-i18next';
import { Meetingroom } from '../interfaces/interfaces';
import { joinMeetingQueue } from '../services/services';
import toast from 'react-hot-toast';

export function useQueue() {
  const { t } = useTranslation();

  async function joinQueue(meetingroom: Meetingroom): Promise<void | boolean> {
    try {
      await joinMeetingQueue(meetingroom._id);
      toast.success(t('event:queue-joined'));
    } catch (err) {
      if ((err as any).response.data.errorKey === 'MeetingOpenError') {
        return true;
      }
      return false;
    }
  }

  return {
    joinQueue,
  };
}
