import { useTranslation } from 'next-i18next';
import React, { Fragment } from 'react';

const UnsupportedBrowser = () => {
  const { t } = useTranslation('common');

  return (
    <Fragment>
      <section className="grid place-items-center h-screen">
        <h1>{t('common:ie11')}</h1>
      </section>
    </Fragment>
  );
};

export default UnsupportedBrowser;
