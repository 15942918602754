import { createSlice } from '@reduxjs/toolkit';
import { DefaultDeviceStates } from '../../interfaces/interfaces';

export interface DeviceState {
  audioInputDevice?: MediaDeviceInfo;
  videoInputDevice?: MediaDeviceInfo;
  audioOutputDevice?: MediaDeviceInfo;
  allAudioInputDevices?: MediaDeviceInfo[];
  allAdioOutputDevices?: MediaDeviceInfo[];
  allVideoInputDevices?: MediaDeviceInfo[];
  defaultDeviceStates: DefaultDeviceStates;
  deviceConfStream?: MediaStream;
}

const initialState: DeviceState = {
  audioInputDevice: undefined,
  audioOutputDevice: undefined,
  videoInputDevice: undefined,
  allAudioInputDevices: undefined,
  allAdioOutputDevices: undefined,
  allVideoInputDevices: undefined,
  defaultDeviceStates: {
    micOn: true,
    camOn: true,
  },
};

const deviceSlice = createSlice({
  name: 'vbw',
  initialState,
  reducers: {
    setAudioInputDevice: (state, action) => {
      state.audioInputDevice = action.payload;
    },
    setAudioOutputDevice: (state, action) => {
      state.audioOutputDevice = action.payload;
    },
    setVideoInputDevice: (state, action) => {
      state.videoInputDevice = action.payload;
    },
    setAudioInputDevices: (state, action) => {
      state.allAudioInputDevices = action.payload;
    },
    setAudioOutputDevices: (state, action) => {
      state.allAdioOutputDevices = action.payload;
    },
    setVideoInputDevices: (state, action) => {
      state.allVideoInputDevices = action.payload;
    },
    setDefaultDeviceStates: (state, action) => {
      state.defaultDeviceStates = action.payload;
    },
    setDeviceConfStream: (state, action) => {
      state.deviceConfStream = action.payload;
    },
  },
});

export const {
  setAudioInputDevice,
  setAudioOutputDevice,
  setVideoInputDevice,
  setAudioInputDevices,
  setAudioOutputDevices,
  setVideoInputDevices,
  setDefaultDeviceStates,
  setDeviceConfStream,
} = deviceSlice.actions;

export default deviceSlice.reducer;
