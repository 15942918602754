import { useTranslation } from 'next-i18next';
import React from 'react';
import { usePermission } from '../../../../hooks/usePermission';
import { useSelector } from '../../../../reduxToolkit/typedSelector';

const MBWCustomFooter: React.FC = () => {
  const event = useSelector((state) => state.common.event);
  const user = useSelector((state) => state.common.user);
  const { t } = useTranslation();
  const { isEventHost } = usePermission();

  const customFooterLinks = event?.organization?.customFooterLinks;
  return (
    <>
      <div
        className={`w-full p-2 flex justify-center overflow-x-auto bg-[#001c38] ${isEventHost(user, event) ? 'pl-[5rem]' : 'pl-2'}`}
        style={{
          backgroundColor: event?.template.mainColor,
          color: event?.template.mainButtonTextColor,
          fontFamily: event?.template.customFont,
        }}>
        <ul className="max-w-[1333px] list-none w-full flex flex-col space-y-1 md:space-y-0 md:flex-row md:justify-between">
          {(!customFooterLinks || customFooterLinks?.length === 0) && (
            <>
              {event?.legal?.hideImprint !== true && (
                <li className="mr-4">
                  <a
                    className={`no-underline transition-colors hover:text-slate-400`}
                    style={{ color: event?.template.mainButtonTextColor }}
                    target="_blank"
                    href={event?.legal?.imprintUrl ? event?.legal?.imprintUrl : 'https://www.vbw-bayern.de/vbw/Impressum/index.jsp'}>
                    {t('common:imprint')}
                  </a>
                </li>
              )}
              <li className="mr-4">
                <a
                  className={`no-underline transition-colors hover:text-slate-400`}
                  style={{ color: event?.template.mainButtonTextColor }}
                  target="_blank"
                  href={event?.legal?.dataPrivacyUrl ? event?.legal?.dataPrivacyUrl : 'https://www.vbw-bayern.de/vbw/Datenschutz/Datenschutz.jsp'}>
                  Datenschutz
                </a>
              </li>
              <li className="mr-4">
                <a
                  className={`no-underline transition-colors hover:text-slate-400`}
                  style={{ color: event?.template.mainButtonTextColor }}
                  target="_blank"
                  href="https://www.vbw-bayern.de/vbw/Kontakt/index-5.jsp">
                  Kontakt
                </a>
              </li>
              <li className="w-40 mr-4 min-w-max">
                <a
                  className={`no-underline transition-colors hover:text-slate-400`}
                  style={{ color: event?.template.mainButtonTextColor }}
                  target="_blank"
                  href="https://www.vbw-bayern.de/vbw/Termine/index-3.jsp">
                  vbw Veranstaltungen
                </a>
              </li>
            </>
          )}
          {customFooterLinks?.map((link) => (
            <li className="w-40 mr-4 min-w-max">
              <a
                className={`no-underline transition-colors hover:text-slate-400`}
                style={{ color: event?.template.mainButtonTextColor }}
                target="_blank"
                href={link.link}>
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={`w-full p-2 flex justify-center overflow-x-auto bg-[#001c38] ${isEventHost(user, event) ? 'pl-[5rem]' : 'pl-2'}`}>
        <div
          className="justify-start w-full transition duration-100 hover:text-slate-400"
          style={{ maxWidth: '1333px', fontFamily: event?.template.customFont }}>
          produced by&nbsp;
          <a className="no-underline transition-colors text-slate-500 hover:text-slate-400" href="https://www.mbw-team.de">
            mbw Medienberatung der Wirtschaft GmbH
          </a>
        </div>
      </div>
    </>
  );
};

export default MBWCustomFooter;
